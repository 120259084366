export default {
    'facility': [
        {
            value: 'PermitNumber',
            label: 'Permit Number',
            type: 'String'
        },
        {
            value: 'FacilityID',
            label: 'RBDMS ID',
            type: 'String',
        },
        {
            value: 'Alias',
            label: 'Sample Point Alias',
            type: 'String'
        }
    ],
    'project': [
        {
            value: 'APINumber',
            label: 'Project Number',
            type: 'String'
        }
    ]
}