import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
} from 'react'
import { DataContext } from '../../DataContext'
import withConfig from '../../../../wrappers/withConfig'
import { useForm } from 'react-hook-form'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import FirstFacilityInformation from './FirstFacilityInformation'

const AssociatedFacilityComponent = withConfig(({
    config,
    setFirstFacility,
    firstFacility,
    setFirstFacilityChanged,
    firstFacilityChanged,
    isAssociatedOG,
}) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods, formType } = useContext(
        DataContext
    )
    const formMethods = useForm({ mode: 'onChange' })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [error, setError] = useState(null)
    const [warning, setWarning] = useState(null)
    const { register, handleSubmit } = formMethods
    const searchDisabled = useMemo(() => !value, [value])
    const { API_URL } = config
    
    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    useEffect(() => {
        if (!isAssociatedOG) {     
            setValue('')
            setError(false)
            setWarning(false)
        }
    }, [isAssociatedOG])

    const search = (v) => {
        setLoading(true)
        setFirstFacility(null)
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Info?columnName=${`FacilityID`}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                if (response && response.facilityInfo && response.facilityInfo.facility && !response.facilityInfo.facility.isMrdb) {
                    setFirstFacility(null)
                    setWarning(null)
                    setError('Facility must be an existing O&G facility.')
                    return
                }
                setFirstFacility(response.facilityInfo)
                setError(null)
                setFirstFacilityChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setWarning(null)
                setFirstFacility(null)
            })
            .finally(() => setLoading(false))
    }
    
    return (
        <form
            className="form"
            onSubmit={handleSubmit(() => search(value))}
        >
            <div className="field is-horizontal is-align-items-center is-full-width">
                <div className="field-label">
                    <label id={`associated-label`} className="label">
                        Associated Facility ID:
                    </label>
                </div>
                <div className="field-body">
                    <div className="field is-grouped">
                        <div className="control is-expanded">
                            <input
                                className={`input is-fullwidth`}
                                onChange={e => {
                                    const v = e.target.value 
                                    setValue(v)
                                    if (v === '') {
                                        setFirstFacility(null)
                                        setFirstFacilityChanged(false)
                                    }
                                    else {
                                        setFirstFacilityChanged(true)
                                    }
                                    setWarning(null)
                                    setError(null)
                                }}
                                value={value}
                                min="1"
                                ref={register}
                                aria-labelledby={`associated-label`}
                                name="columnValue"
                                type="number"
                                disabled={!isAssociatedOG}
                            />
                        </div>
                        <div className="control">
                            <button
                                type="submit"
                                disabled={searchDisabled}
                                className={`button is-primary mr-3 ${
                                    loading ? 'is-loading' : ''
                                }`}
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {warning ? (
                <div className="has-text-centered darkish-orange">
                    {warning}
                </div>
            ) : null}
            {error ? (
                <div className="has-text-centered has-text-danger">
                    {error}
                </div>
            ) : null}
            {firstFacility && firstFacility.facility && !firstFacilityChanged ? (
                <div className="column is-12">
                    <div className="columns is-centered">
                        <div className="column is-10">
                            <FirstFacilityInformation
                                firstFacility={firstFacility}
                                facilityChanged={firstFacilityChanged}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </form>
        
    )
})

export default AssociatedFacilityComponent