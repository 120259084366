import React, { useEffect, useRef, useState } from 'react'
import { dateToString } from '../../../utils/dateUtils'

export default ({ active, payload, label, viewBox, coordinate, stagger }) => {
    const [top, setTop] = useState(0)
    const tooltipRef = useRef()

        useEffect(() => {
            if (tooltipRef && tooltipRef.current && active) {
                const gridBottom = viewBox.top + viewBox.height
                const yCoordinate = coordinate.y
                const tooltipHeight = tooltipRef.current.offsetHeight
                const tooltipBottom = tooltipHeight + yCoordinate
                if (tooltipBottom > gridBottom) {
                    const t = -Math.round((tooltipBottom - gridBottom)) - (stagger ? stagger : 0)
                    setTop(t)
                } else {
                    setTop(0)
                }
            }
    }, [payload, active, viewBox, coordinate, tooltipRef])

    if (active) {
        const formattedLabel = dateToString(label)
        return (
            <div className="customTooltip" ref={tooltipRef} style={{top, position: 'relative'}}>
                <p className="customTooltipLabel">{formattedLabel}</p>
                <ul className="customTooltipList">
                    {payload &&
                        payload.map((x, idx) => {
                            return x.name !== 'NoTooltipItem' ? (
                                <li style={{ color: x.color }} key={idx}>
                                    <span className="customTooltipItemName">
                                        {x.name}
                                    </span>
                                    <span> : </span>
                                    <span className="customTooltipItemValue">
                                        {Math.round(x.value * 100) / 100}
                                    </span>
                                    {x.unit && <span className="customTooltipItemUnit">{` ${x.unit}`}</span>}
                                </li>
                            ) : null
                        })}
                </ul>
            </div>
        )
    }
    return null
}
