import React, {
    useState,
    useContext,
    useEffect,
} from 'react'
import { useForm } from 'react-hook-form'

import Select from 'react-select'
import SelectStyles from '../../../../elem/form/SelectStyles'
import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { DataContext } from '../../DataContext'
import WithConfig from '../../../../wrappers/withConfig'
import FacilityInformation from './FacilityInformation'

const IdentifySamplePointForm = WithConfig(
({
    config,
    facilityData,
    setFacilityData,
    setContinueDisabled,
    facilityChanged,
    setFacilityChanged,
    uploadConfig,
    isAssociatedOG,
}) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setFormMethods, formType, activeAgency } = useContext(DataContext)
    const formMethods = useForm({ mode: 'onChange' })
    const [valuesChanged, setValuesChanged] = useState(false)
    const [error, setError] = useState()
    const { API_URL } = config
    const [targetField, setTargetField] = useState('SamplePoint')
    const [facilityIdentifierValue, setFacilityIdentifierValue] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    useEffect(() => {
        if (facilityIdentifierValue !== '' && !valuesChanged) {
            setFacilityChanged(false)
        } else {
            setFacilityChanged(true)
            //clear errors if project changes
            setError(null)
        }
    }, [valuesChanged, facilityIdentifierValue, targetField])

    const search = (v, field) => {
        if (targetField === `APINumber` && !isAssociatedOG) {
            setError(`API Number is only a valid search option when the sample point is associated with an O&G facility.`)
            return
        }
        setLoading(true)
        setFacilityData(null)
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Info?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFacilityData(response.facilityInfo)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setFacilityData(null)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div className="columns">
                <div className="column is-4">
                    <div className="field is-horizontal is-align-items-center">
                        <div className="field-label">
                            <label className="label">{`Identify Sample Point By:`}</label>
                        </div>
                        <div className="field-body">
                            <div className="field is-grouped">
                                <Select
                                    className="select is-multiple is-fullwidth reactSelect"
                                    classNamePrefix="reactSelect"
                                    // inputRef={ref}
                                    options={[
                                        { value: 'SamplePoint', label: 'Sample Point ID' },
                                        { value: 'APINumber', label: 'API Number' },
                                    ]}
                                    styles={SelectStyles}
                                    menuPlacement="auto"
                                    name="IdentifierValue"
                                    defaultValue={[{ value: 'SamplePoint', label: 'Sample Point ID' }]}
                                    onChange={data => {
                                        setValuesChanged(true)
                                        setTargetField(data.value)
                                        setFacilityIdentifierValue('')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-7">
                    <div className="field is-horizontal field-body field control">
                        <input
                            className={`input is-fullwidth`}
                            name={'facilityInfo'}
                            type="text"
                            // ref={formMethods.register}
                            value={facilityIdentifierValue}
                            onChange={e => {
                                setValuesChanged(true)
                                setFacilityIdentifierValue(e.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="column is-1 is-justify-content-end">
                    <button
                        type="button"
                        disabled={!facilityIdentifierValue}
                        className={`button is-primary ${loading ? 'is-loading' : ''}`}
                        onClick={() => search(facilityIdentifierValue, targetField)}
                    >
                        Search
                    </button>
                </div>
            </div>
            {error ? (
                <div className="has-text-centered has-text-danger">
                    {error}
                </div>
            ) : (
                <div className="column is-12">
                    <div className="columns is-centered">
                        <div className="column is-10">
                            <FacilityInformation
                                data={facilityData}
                                dataChanged={facilityChanged}
                                uploadConfig={uploadConfig}
                            />
                        </div>
                    </div>
                </div>
            )}  
        </>
    )
})

export default IdentifySamplePointForm