import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import Select from 'react-select'
import { useForm } from 'react-hook-form'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import SelectStyles from '../../../elem/form/SelectStyles'
import withConfig from '../../../wrappers/withConfig'
import {
    aliasHelperText,
} from '../../../../utils/submissions/helperText'
import defaultUploadObjects from '../../../../utils/submissions/defaultUploadObjects'
import searchOptions from '../../../../utils/submissions/searchOptions'
import ToggleComponent from './ToggleComponent'
import FacilityInformation from './facilitySearch/FacilityInformation'
import ProjectInformation from './projectSearch/ProjectInformation'

const ExistingSearchComponent = withConfig(({ config, setSearchData, targetField, setTargetField, setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods, formType } = useContext(DataContext)
    const formMethods = useForm({ mode: 'onChange' })
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [valuesChanged, setValuesChanged] = useState(false)
    const [error, setError] = useState(null)
    const { register, handleSubmit } = formMethods
    const searchDisabled = useMemo(() => !value, [value])
    const { API_URL } = config
    const options = searchOptions[formType.PrimaryAccessor]

    useEffect(() => {
        if (value !== "" && !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, value])

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = (v, field) => {
        const option = options.find(x => x.value === field)
        if (v === '') {
            setError('Please enter a value.')
            setSearchData({})
            return
        }
        if (field === '' || field === null) {
            setError(`Please select an identifier for the existing ${formType.Singular}.`)
            setSearchData({})
            return
        }
        if (option.type === 'Int') {
            if (parseFloat(v) !== parseInt(v)) {
                setError('Value should be an integer.')
                setSearchData({})
                return
            }
            if (typeof(option.minValue) !== 'undefined' && option.minValue && parseInt(v) < option.minValue) {
                setError(`Value cannot be less than ${option.minValue}.`)
                setSearchData({})
                return
            }
        }
        setLoading(true)
        setSearchData({})
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Info?columnName=${field}&columnValue=${v}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setSearchData(response[`${formType.PrimaryAccessor}Info`])
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setSearchData({})
            })
            .finally(() => setLoading(false))
    }

    return (<>
        <form className="form"
            onSubmit={handleSubmit(() => search(value, targetField))}
        >
            <div className="field is-horizontal is-align-items-center">
                <div className="field-label">
                    <label className="label">{`Identify ${formType.FormType} By:`}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <Select
                            className="select is-multiple is-fullwidth reactSelect"
                            classNamePrefix="reactSelect"
                            options={options}
                            name="columnName"
                            // ref={register}
                            styles={SelectStyles}
                            menuPlacement="auto"
                            onChange={data => {
                                setValue('')
                                setTargetField(data.value)
                                setValuesChanged(true)
                            }}
                        />
                    </div>
                    <div className="field">
                        <input
                            className={`input is-fullwidth`}
                            onChange={e => {
                                setValuesChanged(true)
                                setValue(e.target.value)
                            }}
                            value={value}
                            ref={register}
                            name="columnValue"
                        />
                    </div>
                    <div className="field">
                        <button
                            type="submit"
                            disabled={searchDisabled}
                            className={`button is-primary ${loading ? 'is-loading' : ''}`}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            {error ? <div className="is-full has-text-centered has-text-danger">{error}</div> : null}
        </form>
    </>)
})

const NewSearchComponent = withConfig(({ config, setSearchData , setContinueDisabled }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, setFormMethods, formType } = useContext(DataContext)
    const [alias, setAlias] = useState(null)
    const [valuesChanged, setValuesChanged] = useState(false)
    const searchDisabled = useMemo(() => (!alias), [alias])
    const formName = formType.FormType

    useEffect(() => {
        if ((!alias) || !valuesChanged) {
            setContinueDisabled(false)
        } else {
            setContinueDisabled(true)
        }
    }, [valuesChanged, alias])

    const [loading, setLoading] = useState(false)
    const formMethods = useForm({ mode: 'onChange' })
    const [error, setError] = useState(null)
    const { handleSubmit } = formMethods
    const { API_URL } = config

    useEffect(() => {
        setFormMethods(formMethods)
    }, [])

    const search = () => {
        setLoading(true)
        setSearchData({})
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/${formType.APILink}Search?alias=${alias}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                const aliasObj = {
                    alias
                }
                const completeData = {
                    ...response[`${formType.PrimaryAccessor}Info`],
                    [`${formType.PrimaryAccessor}Alias`]: aliasObj
                }
                setSearchData(completeData)
                setError(null)
                setValuesChanged(false)
            })
            .catch(e => {
                setError(e.message)
                setSearchData({})
            })
            .finally(() => setLoading(false))
    }

    const aboveAliasText = formType.APILink === 'project'
        ? `
            Project Alias is an optional field. If Project Alias is left blank,
             the project will be identified by the Project Number after this form is accepted.
        ` : `
            ${formName} Alias is an optional field. If ${formName} Alias is left blank,
            the ${formType.Singular} will be identified by the ${formType.NewlyCreatedIDName} after this form is accepted.
        `

    const belowAliasText = formType.APILink === 'project' 
        ? `Project Alias is the name or number used by the submitter to identify this project.` 
        : aliasHelperText.replace('%IDENTIFIER%', formType.Singular).replaceAll('%NAME%', formName)

    return (
        <form className="form" onSubmit={handleSubmit(d => search())}>
            <div className="is-size-4 has-text-centered matchingFacilityText">
                <span className="has-text-weight-semibold">{`${formName} Identifiers: `}</span>
                <span className="help has-text-grey">
                    <p>{aboveAliasText}</p>
                </span>
            </div>
            <div className="m-0 columns field is-horizontal is-align-items-center">
                <div className="column is-2 field-label">
                    <label className="label">{`${formName}`} Alias:</label>
                </div>
                <div className="column is-9 field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                className="input is-fullwidth "
                                name={'Alias'}
                                onChange={e => {
                                    setValuesChanged(true)
                                    setAlias(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="column is-1 field">
                    <button type="submit" disabled={searchDisabled} className={`button is-primary ${loading ? 'is-loading' : ''}`}>
                        Search
                    </button>
                </div>
            </div>
            <span className="m-0 has-text-centered help has-text-grey">{belowAliasText}</span>
            {error ? (
                <div className="column is-12 has-text-centered">
                    <div className="has-text-danger">{error}</div>
                </div>
            ) : null}
        </form>
    )
})

const SaveAndContinueButton = ({ searchData, continueDisabled }) => {
    const { setSubmissionState, isSubmitting, setTableData, formType } = useContext(DataContext)
    
    const emptyUploadState = defaultUploadObjects[formType.Link]
    
    const mergeData = useCallback(() => {
        setSubmissionState(prevSubmissionState => ({
            ...prevSubmissionState,
            ...emptyUploadState,
            new: Math.random() * 1000,
            ...searchData
        }))
        setTableData({})
    }, [searchData])

    return (
        <div className="buttonWrapper">
            <button
                type="button"
                onClick={() => mergeData()}
                disabled={continueDisabled} 
                className={`button is-medium is-link ${isSubmitting ? 'is-loading' : ''}`}
            >
                {`Continue`}
            </button>
        </div>
    )
}

const getInformationComponent = accessor => {
    switch (accessor) {
        case 'facility':
            return FacilityInformation
        case 'project':
            return ProjectInformation
        default:
            return FacilityInformation
    }
}

const SearchForm = ({ formType }) => {
    const [isNew, setIsNew] = useState(true)
    const { 
        activePanel,
        submissionState,
        setPrimaryKey,
        uploadConfig,
    } = useContext(DataContext)
    const [continueDisabled, setContinueDisabled] = useState(false)
    const [targetField, setTargetField] = useState(null)
    const [searchData, setSearchData] = useState(submissionState)

    const InformationComponent = React.createElement(getInformationComponent(formType.PrimaryAccessor), {
        data: searchData,
        dataChanged: false,
        uploadConfig,
    })

    return (
        <div
            className={`columns is-multiline ${
                activePanel !== `${formType.FormType} Search` ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <ToggleComponent
                    onDropDownChange={newItem => {
                        setSearchData(null)
                        setPrimaryKey(null)
                        setIsNew(newItem)
                    }}
                    formType={formType}
                />
            </div>
            <div className="column is-12">
                {isNew ? (
                    <NewSearchComponent 
                        setSearchData={setSearchData}
                        setContinueDisabled={setContinueDisabled}
                    />
                ) : (
                    <ExistingSearchComponent 
                        setSearchData={setSearchData}
                        targetField={targetField}
                        setTargetField={setTargetField}
                        setContinueDisabled={setContinueDisabled}
                    />
                )}
            </div>
            <div className="column is-12">
                <div className="columns is-centered">
                    <div className="column is-6">
                        {InformationComponent}
                    </div>
                </div>
            </div>
            <div className="column is-12">
                <SaveAndContinueButton 
                    searchData={searchData}
                    continueDisabled={continueDisabled}
                />
            </div>
        </div>
    )
}

export default SearchForm
