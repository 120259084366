import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'

import urls, { createSubmissionUrl } from '../../../utils/constants/urls'
import Breadcrumbs from './form/Breadcrumbs'
import { DataContext } from './DataContext'
import { SimpleSpinner } from '../../elem/Spinner'
import { UserContext } from '../../wrappers/UserContext'

export default () => {
    const { roles } = useContext(UserContext)
    const { formTypes } = useContext(DataContext)
    if (!formTypes) {
        return <SimpleSpinner />
    }

    return (
        <div className="hero is-fullheight-with-navbar">
            <Breadcrumbs />
            <div className="hero-body">
                <div className="container">
                    {formTypes.map((formType, idx) => {
                        let shouldDisplay = true
                        if (formType.AllowedRoles) {
                            shouldDisplay = !!formType.AllowedRoles.split(',').find(x => roles.includes(x))
                        }
                        return shouldDisplay ? (
                            <div className="section" key={idx}>
                                <div className="columns is-centered is-vcentered">
                                    <div className="column is-6">
                                        <article className={`message is-${idx}`}>
                                            <div className="message-header space-between">
                                                <Link
                                                    to={createSubmissionUrl(
                                                        urls.uploadDialogue,
                                                        formType.Link
                                                    )}
                                                    className="no-underline full-width"
                                                >
                                                    <div className="headerWrapper space-between">
                                                        <span className="headerText display-flex center-vertically">
                                                            {`${formType.NewFormName} Submission`}
                                                        </span>
                                                        <span className="iconWrapper icon is-medium">
                                                            <FaChevronRight />
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="message-body">
                                                {formType.NewFormDescription}
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    )}
                </div>
            </div>
        </div>
    )
}
