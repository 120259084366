import React, { useContext, useCallback } from 'react'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { FaDownload } from 'react-icons/fa'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import withConfig from '../../../wrappers/withConfig'
import actions from '../../../../utils/submissions/actions'
import toast from '../../../elem/Toast'

export default withConfig(({ config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { formType } = useContext(DataContext)
    const { API_URL, EDD_TEMPLATE_TITLE } = config

    const downloadTemplate = useCallback(() => {
        if (actions.isValidFormType(formType)) {
            authenticatedFetch(`${API_URL}/upload/${formType.APILink}/downloadTemplate`)
                .then(async response => {
                    if (response.ok) {
                        return response.blob()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(data => {
                    const dateString = dayjs(Date.now()).format('MM-DD-YYYY')
                    saveAs(data, `${EDD_TEMPLATE_TITLE}_${dateString}.xlsx`)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Download Failed: ' +
                            (e.message
                                ? e.message
                                : 'Contact the system administrator to ensure the file is present on the server.'),
                    })
                })
            }
    }, [formType])

    return (
        <div className="center-content">
            <button type="button" className="button is-medium is-primary" onClick={() => downloadTemplate()}>
                <span className="icon">
                    <FaDownload />
                </span>
                <span>
                    Download EDD Template
                </span>
            </button>
        </div>
    )
})
