import React from 'react'

import WellExplorer from './lists/WellExplorer'
import SampleExplorer from './lists/SampleExplorer'
import Map from './map/Map'
import WellDetail from './wellDetail/Detail'
import Aggregate from './charts/aggregate/Aggregate'

// import WaterLevel from './charts/waterLevel/WaterLevel'
// import WaterLevelExplorer from './lists/WaterLevelExplorer'

export default () => (
    <div className="spaWrapper">
        <WellDetail />
        <div className="columns spaContainer is-full is-multiline is-1 is-variable">
            <div className="column is-one-third-desktop is-full-tablet is-full-mobile">
                {/* <WaterLevelExplorer width={'one-third'} /> */}
                <SampleExplorer width={'one-third'} />
                {/* <WaterLevel width={'one-third'} /> */}
                <Aggregate width={'one-third'} />
            </div>
            <div className="column is-two-thirds-desktop is-full-tablet is-full-mobile">
                <Map width={'full'} />
                <WellExplorer width={'full'} />
            </div>
        </div>
    </div>
)
