import { convertFieldDuplicateString, getConfigRefCodeValue } from "./values";
import { decapitalizeText } from "../stringUtils";

const formattingColumnNameRegex = /\$\w+/g;

const getRelatedTableCodeDescription = (row, foreignKeyNameFormat, uploadConfig) => {
    const columnNames = foreignKeyNameFormat.match(formattingColumnNameRegex)
    let relatedTableCodeDescription = foreignKeyNameFormat
    const replacements = columnNames.map(escapedColumnString => {
        const columnName = escapedColumnString.substring(1)
        const rowColumnName = decapitalizeText(columnName)
        const existingValue =  row[rowColumnName]
        const refCodeValue = getConfigRefCodeValue(columnName, existingValue, uploadConfig)

        if (existingValue === '' || existingValue === null || existingValue === undefined ) {
            return { search: escapedColumnString, replace: 'None'}
        }

        if (refCodeValue === existingValue) {
            return {
                search: escapedColumnString, replace: existingValue
            }
        } else {
            return { search: escapedColumnString, replace: refCodeValue }
        }
        
    })
    replacements.map(replacement => relatedTableCodeDescription = relatedTableCodeDescription.replace(replacement.search, replacement.replace))
    return relatedTableCodeDescription
}

const getRequiredColumnValues = (foreignKeyAccessor, foreignKeyColumnName, foreignKeyNameFormat, uploadConfig, submissionState) => {
    let foreignKeyData = submissionState[foreignKeyAccessor]
    if (
        foreignKeyAccessor && foreignKeyData &&
        Array.isArray(foreignKeyData)
    ) {
        const valueAccessor = foreignKeyColumnName
        const values = foreignKeyData.length 
        ? foreignKeyData.map(x => {
                const codeDescriptionSuffix = convertFieldDuplicateString(x['isFieldDuplicate'], foreignKeyColumnName)
                return ({
                  code: x[valueAccessor],
                  codedescription: `${getRelatedTableCodeDescription(x, foreignKeyNameFormat, uploadConfig)}${codeDescriptionSuffix}`,
                  active: true,
                })
            }).filter(x => x && x.codedescription && x.codedescription !== 'null') : []
        return { columnName: `${valueAccessor}`, values }
    } else {
        return { columnName: `${foreignKeyColumnName}`, values: [] }
    }
}

const populateConfigWithForeignKeyValues = (uploadConfig, submissionState) => {
    const populatedConfig = uploadConfig.map(row => {
        const controlName = row.ControlName
        const foreignKeyAccessor = row.ForeignKeyAccessor
        const foreignKeyColumnName = decapitalizeText(row.ColumnName)
        const foreignKeyNameFormat = row.ForeignKeyNameFormat
        const requiredColumnValues = getRequiredColumnValues(
            foreignKeyAccessor,
            foreignKeyColumnName,
            foreignKeyNameFormat,
            uploadConfig,
            submissionState,
            controlName
        )
        if (foreignKeyAccessor && requiredColumnValues && requiredColumnValues.values && requiredColumnValues.values) {
            row.Values = requiredColumnValues.values
        }
        return row
    })
    return populatedConfig
}

export { getRelatedTableCodeDescription, getRequiredColumnValues, populateConfigWithForeignKeyValues}