import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import toast from '../../../elem/Toast'
import { UserContext } from '../../../wrappers/UserContext'
import { DataContext } from '../DataContext'
import withConfig from '../../../wrappers/withConfig'
import actions from '../../../../utils/submissions/actions'

const EDDDataContext = createContext(null)

const EDDDataContextProvider = ({ config, children }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, formType } = useContext(DataContext)
    const { user } = useContext(UserContext)
    const [validationResults, setValidationResults] = useState(null)
    const [eddConfig, setEDDConfig] = useState([])
    const [loading, setLoading] = useState(false)
    const [formMethods, setFormMethods] = useState({})
    const [uploadId, setUploadId] = useState(null)
    const [controlledPageSize, setControlledPageSize] = useState(null)
    const { API_URL } = config

    const POSTFORM = upload => {
        const formData = new FormData()
        formData.append('File', upload.File)
        formData.append('AgencyCode', upload.AgencyCode)
        formData.append('UploadUser', upload.UploadUser)
        formData.append('FormType', upload.FormType)
        
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                // 'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: formData,
        }
    }

    const POST = data => {
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(data),
        }
    }

    const validateFile = useCallback(
        file =>
            new Promise((resolve, reject) => {
                if (!(user && user.profile) || !actions.isValidFormType(formType)) {
                    return reject()
                }
                setLoading(true)
                setValidationResults(null)
                authenticatedFetch(
                    `${API_URL}/upload/${formType.APILink}/validateEDD`,
                    POSTFORM({
                        File: file,
                        AgencyCode: activeAgency,
                        UploadUser: user.profile.name,
                    })
                )
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        setValidationResults(response)
                        return resolve(response)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Validate EDD:' +
                                (e.message
                                    ? e.message
                                    : 'Validate EDD Failed.'),
                        })
                        return reject()
                    })
                    .finally(() => setLoading(false))
            }),
        [activeAgency, user, formType]
    )

    const validateTable = useCallback(
        data =>
            new Promise((resolve, reject) => {
                if (!(user && user.profile) || !actions.isValidFormType(formType)) {
                    return reject()
                }
                setLoading(true)
                setValidationResults(null)
                authenticatedFetch(
                    `${API_URL}/upload/${formType.APILink}/updateEDD`,
                    POST({
                        Data: data,
                        AgencyCode: activeAgency,
                        UploadUser: user.profile.name,
                        UploadId: uploadId ? Number(uploadId) : null,
                    })
                )
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        var validationResult = response.validationResult
                        let errors = []
                        if (
                            validationResult &&
                            Object.keys(validationResult.errors).length
                        ) {
                            // console.log(validationResult.errors)
                            // get all fields in the form
                            const formFields = formMethods.watch()

                            const formFieldDataAccessors = Object.keys(
                                formFields
                            ).map(x => x.split('.')[0])

                            // get the error fields associated with the form
                            const errorFields = [
                                ...new Set(
                                    Object.keys(
                                        validationResult.errors
                                    ).filter(x =>
                                        formFieldDataAccessors.includes(x)
                                    )
                                ),
                            ]
                            errorFields.forEach(errorField => {
                                // find the errors associated with this field
                                const validationErrors =
                                    validationResult.errors[errorField]
                                validationErrors.forEach(error => {
                                    const associatedField = Object.keys(
                                        formFields
                                    ).find(x =>
                                        x.includes(
                                            `${errorField}.${error.fieldName}`
                                        )
                                    )
                                    formMethods.setError(
                                        associatedField,
                                        'custom',
                                        error.errorString
                                    )
                                })
                            })

                            if (validationResult.errors['EDD']) {
                                errors = validationResult.errors['EDD'].filter(
                                    x => x.fieldName === null
                                )
                            }
                        }

                        setValidationResults({
                            errors: { EDD: errors },
                            warnings: response.validationResult.warnings,
                            pageErrorText: getPageErrorText(response.validationResult.errors, controlledPageSize)
                        })
                        return resolve(response.validationResult)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Validate Table:' +
                                (e.message
                                    ? e.message
                                    : 'Validate Table Failed.'),
                        })
                        return reject()
                    })
                    .finally(() => setLoading(false))
            }),
        [activeAgency, user, uploadId, eddConfig, controlledPageSize, formType]
    )

    const uploadFile = useCallback(
        file =>
            new Promise((resolve, reject) => {
                if (!(user && user.profile) || !actions.isValidFormType(formType)) {
                    return reject()
                }
                setLoading(true)
                setValidationResults(null)
                authenticatedFetch(
                    `${API_URL}/upload/${formType.APILink}/uploadEDD`,
                    POSTFORM({
                        File: file,
                        AgencyCode: activeAgency,
                        UploadUser: user.profile.name,
                        FormType: formType.FormType
                    })
                )
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(validationResults => {
                        return resolve(validationResults)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Upload EDD:' +
                                (e.message ? e.message : 'Upload EDD Failed.'),
                        })
                        return reject()
                    })
                    .finally(() => setLoading(false))
            }),
        [activeAgency, user, formType]
    )

    const uploadTable = useCallback(
        data =>
            new Promise((resolve, reject) => {
                if (!(user && user.profile) || !actions.isValidFormType(formType)) {
                    return reject()
                }
                setLoading(true)
                setValidationResults(null)
                authenticatedFetch(
                    `${API_URL}/upload/${formType.APILink}/updateEDD`,
                    POST({
                        Data: data,
                        AgencyCode: activeAgency,
                        UploadUser: user.profile.name,
                    })
                )
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        var validationResult = response.validationResult
                        if (
                            validationResult &&
                            Object.keys(validationResult.errors).length
                        ) {
                            // get all fields in the form
                            const formFields = formMethods.watch()

                            const formFieldDataAccessors = Object.keys(
                                formFields
                            ).map(x => x.split('.')[0])

                            // get the error fields associated with the form
                            const errorFields = [
                                ...new Set(
                                    Object.keys(
                                        validationResult.errors
                                    ).filter(x =>
                                        formFieldDataAccessors.includes(x)
                                    )
                                ),
                            ]

                            errorFields.forEach(errorField => {
                                // find the errors associated with this field
                                const validationErrors =
                                    validationResult.errors[errorField]
                                validationErrors.forEach(error => {
                                    const associatedField = Object.keys(
                                        formFields
                                    ).find(x =>
                                        x.includes(
                                            `${errorField}.${error.fieldName}`
                                        )
                                    )
                                    formMethods.setError(
                                        associatedField,
                                        'custom',
                                        error.errorString
                                    )
                                })
                            })

                        }
                        setValidationResults({
                            errors: [],
                            warnings: response.validationResult.warnings,
                        })
                        return resolve(response.validationResult)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Validate Table:' +
                                (e.message
                                    ? e.message
                                    : 'Validate Table Failed.'),
                        })
                        return reject()
                    })
                    .finally(() => setLoading(false))
            }),
        [activeAgency, user]
    )

    useEffect(() => {
        if (actions.isValidFormType(formType)) {
            actions.getEDDConfig(authenticatedFetch, API_URL, formType, setEDDConfig)
        }
    }, [formType])

    const getPageErrorText = (errorState, controlledPageSize) => {
        if (Object.keys(errorState).length && controlledPageSize) {
            const rowsWithErrors = Object.keys(errorState).filter(x => x.includes('[')).map(x => x.replace('EDD', '').replace('[', '').replace(']', ''))
            const pagesWithErrors = rowsWithErrors.map(x => Number(x)).map(x => (Math.floor(x / controlledPageSize) + 1))
            const pageString = [...pagesWithErrors.sort()].join(', ')
            return `Record(s) have errors in the following pages: ${pageString}`
        } else {
            return null
        }
    }

    return (
        <EDDDataContext.Provider
            value={{
                validateFile,
                validateTable,
                uploadFile,
                uploadTable,
                setUploadId,
                validationResults,
                loading,
                eddConfig,
                setFormMethods,
                formMethods,
                setControlledPageSize
            }}
        >
            {children}
        </EDDDataContext.Provider>
    )
}

export { EDDDataContext }
export default withConfig(EDDDataContextProvider)
