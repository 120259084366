import React, { useContext, useEffect, useState } from 'react'

import { DataContext } from '../DataContext'

export default ({ accessor }) => {
    const { activePanel, navGroups, errorState, uploadConfig } = useContext(DataContext)
    const [errors, setErrors] = useState(null)
    const [warnings, setWarnings] = useState(null)

    useEffect(() => {
        const applicableAccessors = [...new Set(uploadConfig.filter(x => x.SectionGroupName === activePanel && x.DataAccessor === accessor).map(x => x.DataAccessor))]

        // row errors are in the key specified by errorsState['<dataAccessor>[idx]'] and have an empty field name
        const generalRowErrors = Object.keys(errorState)
        .filter(x => x.includes('['))
        .reduce((acc, curr) => {
          const errors = errorState[curr].filter(x => x.fieldName === "")
          const dataAccessor = curr.split('[')[0]
          if (errors) {
              if (applicableAccessors.includes(dataAccessor)) {
                return [
                    ...acc,
                    ...errors
                ]
              }
          }
          return acc
        }, [])

        // general table errors are in the key errorState[dataAccessor]
        const generalTableErrors = Object.keys(errorState)
        .reduce((acc, curr) => {
          const errors = errorState[curr].filter(x => x.fieldName === "")
          const dataAccessor = curr
          if (errors) {
              if (applicableAccessors.includes(dataAccessor)) {
                return [
                    ...acc,
                    ...errors
                ]
              }
          }
          return acc
        }, [])

        const generalErrors = [
            ...generalRowErrors,
            ...generalTableErrors
        ]
    
        setErrors(generalErrors ? [...new Set(generalErrors.filter(x => x.severity === 'Error').map(x => x.errorString))] : null)
        setWarnings(generalErrors ? [...new Set(generalErrors.filter(x => x.severity === 'Warning').map(x => x.errorString))] : null)
        
    }, [activePanel, navGroups, errorState, uploadConfig])

    if (!errors) {
        return null
    } else {
        return (
            <div>
                <ul>
                    {errors.map(x => (
                        <li className="has-text-danger-dark">
                            {x}
                        </li>
                    ))}
                    {warnings.map(x => (
                        <li className="has-text-warning-dark">
                            {x}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}