
const generateDateParams = (params, explorerName = 'sample', fieldName = 'SimpleSampleDate') => {
    const valueField = `${fieldName}Value`
    const operatorField = `${fieldName}Operator`
    const dates = (params[explorerName] && params[explorerName][valueField]) ? decodeURIComponent(params[explorerName][valueField]).split(',') : []
    const operators = (params[explorerName] && params[explorerName][operatorField]) ? decodeURIComponent(params[explorerName][operatorField]).split(',') : []

    // if there are no dates, then return null values for start and end date
    if (!(dates.length)) {
        return [null, null]
    }

    // get the first and second dates in the array
    const firstDate = dates[0]
    const firstOperator = operators[0]
    const secondDate = dates.length > 1 ? dates[1] : null
    const secondOperator = operators.length > 1 ? operators[1] : null

    // use the comparison operator to construct start and end date
    if (secondOperator) {
        // in the case of between, there are two values. these will always be 
        // in pairs of [startDate, endDate] [>=, <=]
        return [firstDate, secondDate]
    } else {
        // in all other cases, we have only one value and we need to use the
        // comparison operator and need to decide if it's start / end date by the comparison operator
        if (firstOperator.includes('<')) {
            return [null, firstDate]
        } else {
            // otherwise it will be date =, >, >= value
            return [firstDate, null]
        }
    }
}

const hasDateParams = (params, explorerName = 'sample', fieldName = 'SimpleSampleDate') => 
    params[explorerName] && params[explorerName][`${fieldName}Value`]
    ? params[explorerName][`${fieldName}Value`]
    : null

const roundToFourDigits = (number) => {
    return Number(Math.round(Number( number.toPrecision(4)) + 'e3') + 'e-3')
}

export { generateDateParams, roundToFourDigits, hasDateParams }