import React, { useContext } from 'react'
import { AppStateContext } from '../AppStateContext'
import { DataContext } from './DataContext'
import { FaTimes } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'
import TimeWindowSelectorButton from '../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../elem/chart/AnalyteSelectorButton'
import ResetDropdownButton from '../../../elem/chart/ResetDropdownButton'
import MatrixSelectorButton from '../../../elem/chart/MatrixSelectorButton'

export default () => {
    const { updateDetailState } = useContext(AppStateContext)
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        resetExpanded,
        toggleResetExpanded,
        displayMatrixWindowDropdown,
        toggleMatrixWindowDropdown
    } = useContext(DataContext)
    return (
        <PanelHeader extraClass="wellDetailPanelHeader">
            <div className="panelHeaderText">Details</div>
            <div className="panelButtons">
                <ResetDropdownButton resetExpanded={resetExpanded} toggleResetExpanded={toggleResetExpanded} />
                <MatrixSelectorButton
                    displayMatrixWindowDropdown={displayMatrixWindowDropdown}
                    toggleMatrixWindowDropdown={toggleMatrixWindowDropdown}
                />
                <AnalyteSelectorButton
                    displayAnalyteWindowDropdown={displayAnalyteWindowDropdown}
                    toggleAnalyteWindowDropdown={toggleAnalyteWindowDropdown}
                />
                <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                />

                <div
                    className="panelButton"
                    onClick={e => {
                        e.preventDefault()
                        updateDetailState('well', { visible: false })
                    }}
                >
                    <FaTimes />
                </div>
            </div>
        </PanelHeader>
    )
}
