import React, { createContext } from 'react'

import { samplePanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import Explorer from '../../../elem/list/Explorer'

const DataContext = createContext(null)

export default ({ width, explorerPage=false}) => {
    return (
        <Explorer 
            formName='sample' 
            name='Sample' 
            apiController='sample' 
            DataContext={DataContext} 
            width={width} 
            explorerPage={explorerPage}
            panelTitle={'Sample Result'}
            panelClassConfig={panelClassConfig}
        />
    )
}