import React from 'react'
import { IconContext } from 'react-icons'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'

import { PanelStyleContext } from './panel/PanelStyleContext'

const UpArrow = ({ className, size, color }) => (
    <PanelStyleContext.Consumer>
        {value => {
            return (
                <IconContext.Provider
                    value={{ className: className, size, color }}
                >
                    <FaAngleUp />
                </IconContext.Provider>
            )
        }}
    </PanelStyleContext.Consumer>
)

const DownArrow = ({ className, size, color }) => (
    <PanelStyleContext.Consumer>
        {value => {
            return (
                <IconContext.Provider
                    value={{ className: className, size, color }}
                >
                    <FaAngleDown />
                </IconContext.Provider>
            )
        }}
    </PanelStyleContext.Consumer>
)

export { UpArrow, DownArrow }
