import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'
import SimpleTable from '../../../elem/table/SimpleTable'
import { FaTrash, FaDownload } from 'react-icons/fa'
import WithConfig from '../../../wrappers/withConfig'
import actions from '../../../../utils/submissions/actions'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import toast from '../../../elem/Toast'
import dayjs from 'dayjs'
import Modal from '../../../elem/Modal'

const UploadAttachments = ({ config }) => {
    const {
        activePanel,
        isSubmitting,
        submissionState,
        setSubmissionState,
    } = useContext(DataContext)
    const { authenticatedFetch } = useContext(APIRequestContext)

    const [file, setFile] = useState([])
    const [displayDeleteModal, setDisplayDeleteModal] = useState({
        display: false,
        tableProps: {},
    })
    const [isUploading, setIsUploading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { API_URL } = config

    const handleFileChange = useCallback(
        event => {
            setErrorMessage('')
            const selectedFiles = event.target.files
            if (!selectedFiles || !selectedFiles.length) {
                setErrorMessage('Please select one or more files to attach.')
                return
            }

            const fileArray = [...selectedFiles]
            const hasFilesThatExceedSizeLimit = selectedFiles
                ? fileArray.some(x => x.size > 128 * 1024 * 1024)
                : false
            if (hasFilesThatExceedSizeLimit) {
                // Check if file size is greater than 128MB
                setErrorMessage(
                    'One of the files exceeds the 128MB size limit.'
                )
                return
            }

            setFile(prevFile => [...prevFile, ...fileArray]) // Use functional update form to update the file state
            event.target.value = null
            return
        },
        [submissionState, setFile]
    )

    const handleDownloadFile = useCallback(
        (uploadAttachmentId, fileName) => {
            actions.downloadAttachment(
                authenticatedFetch,
                API_URL,
                uploadAttachmentId,
                fileName
            )
        },
        [API_URL, authenticatedFetch]
    )

    const onDeleteClick = useCallback(
        deletedFile => {
            try {
                const uploadAttachmentId =
                    deletedFile.original.uploadAttachmentId
                setDisplayDeleteModal({
                    display: true,
                    tableProps: { uploadAttachmentId },
                })
            } catch (error) {
                console.error(error, 'delete error')
            }
        },
        [setDisplayDeleteModal]
    )

    const handleDeleteFile = useCallback(async () => {
        const tableProps = displayDeleteModal.tableProps
        const uploadAttachmentId = tableProps.uploadAttachmentId

        // try to delete the attachment
        try {
            await actions.deleteAttachment(
                authenticatedFetch,
                API_URL,
                uploadAttachmentId
            )
            // if the delete was successful, update the submissionState
            // get a list of attachments from the submissionState
            const attachments = submissionState.attachment

            // remove the attachment from the list of attachments
            const newAttachments = attachments.filter(
                attachment =>
                    attachment.uploadAttachmentId !== uploadAttachmentId
            )

            // update any references to the deleted attachment in the samples array
            const samples = submissionState.sample
            const newSamples = samples.map(sample => {
                const newSample = { ...sample }
                newSample.uploadAttachmentId = null
                return newSample
            })

            // update the submissionState with the new list of attachments
            setSubmissionState(prevState => ({
                ...prevState,
                attachment: newAttachments,
                sample: newSamples,
            }))
            return
        } catch (e) {
            toast({
                level: 'error',
                message: 'Error deleting attachment: ' + e.message,
                alert: true,
            })
        } finally {
            setDisplayDeleteModal({ display: false, tableProps: {} })
        }
        return
    }, [API_URL, authenticatedFetch, setSubmissionState, displayDeleteModal])

    const clearAttachments = useCallback(() => {
        setFile([])
        setIsDisabled(false)
        setErrorMessage('')
    }, [file])

    const uploadAttachments = useCallback(async () => {
        try {
            setIsUploading(true)
            const uploadId = submissionState.uploadId || null
            const response = await actions.uploadAttachments(
                authenticatedFetch,
                API_URL,
                uploadId,
                file
            )
            if (response) {
                setSubmissionState(prevState => ({
                    ...prevState,
                    attachment: response,
                }))
                setFile([])
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsUploading(false)
        }
    }, [API_URL, authenticatedFetch, file, submissionState, setSubmissionState])

    // CWP2-351 - enable the delete action only if the submission is pending or in review (uploadStatus === 0 || uploadStatus === 1)
    // additionally, decided to disable the add attachments dialogue with this flag.
    const allowAttachmentActions = useMemo(() => {
        if (submissionState) {
            return submissionState.uploadStatus == 0 || submissionState.uploadStatus == 1
        }
        return false
    }, [submissionState])

    const columns = useMemo(() => {
        let c = [
            { Header: 'File Name', accessor: 'fileName' },
            {
                Header: 'Upload Date',
                accessor: 'uploadedDate',
                Cell: ({ value }) => {
                    return new dayjs(value).format('MM/DD/YYYY')
                },
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div>
                        <FaDownload
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                handleDownloadFile(
                                    row.original.uploadAttachmentId,
                                    row.original.fileName
                                )
                            }
                            className={'marginIcon'}
                        />
                        {allowAttachmentActions ? (
                            <FaTrash
                                style={{ cursor: 'pointer' }}
                                onClick={() => onDeleteClick(row)}
                            />
                        ) : null}
                    </div>
                ),
            },
        ]
        return c
    }, [handleDeleteFile, handleDownloadFile, allowAttachmentActions])

    return (
        <div
            className={`columns is-multiline
            ${activePanel !== 'Upload Attachments' ? 'is-hidden' : ''}
        `}
        >
            <div className="column is-10" style={{ margin: 24 }}>
                {allowAttachmentActions ? (
                    <SectionWrapper title={'Add Attachments'}>
                        <div className="content">
                            <p>
                                Please upload files to add as attachments; max file
                                size accepted is 128MB
                            </p>
                        </div>
                        <div className="content">
                            <form className="form">
                                <label
                                    htmlFor="fileInput"
                                    className="attachmentTitle"
                                >
                                    Attachments
                                </label>
                                <div>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileChange}
                                        multiple
                                        className="fileInput"
                                    />
                                </div>
                                {errorMessage && (
                                    <p style={{ color: 'red' }}>{errorMessage}</p>
                                )}
                                <div className="content">
                                    {file && (
                                        <ul>
                                            {file.map(file => (
                                                <li key={file.name}>{file.name}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <button
                                    type="button"
                                    className={`button ${
                                        file.length > 0 ? '' : 'is-hidden'
                                    }`}
                                    onClick={clearAttachments}
                                >
                                    Clear Attachments
                                </button>
                                <button
                                    type="button"
                                    className={`button ${
                                        file.length == 0 ? 'is-hidden' : ''
                                    }`}
                                    onClick={uploadAttachments}
                                >
                                    Upload Attachments
                                </button>
                            </form>
                        </div>
                    </SectionWrapper>
                ) : null}
                <label className="subtitle">Attachments</label>
                <SimpleTable
                    data={submissionState.attachment}
                    columns={columns}
                />
                <Modal
                    display={displayDeleteModal.display}
                    confirmAction={() => {
                        handleDeleteFile()
                        setDisplayDeleteModal({
                            ...displayDeleteModal,
                            display: false,
                        })
                    }}
                    confirmText={`Delete`}
                    denyAction={() =>
                        setDisplayDeleteModal({
                            ...displayDeleteModal,
                            display: false,
                        })
                    }
                    denyText={`Cancel`}
                    title={'Delete Confirmation'}
                >
                    Are you sure you want to delete this record?
                </Modal>
            </div>
        </div>
    )
}

export default WithConfig(UploadAttachments)
