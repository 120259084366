import React, { Fragment, useContext } from 'react'

import PanelHeader from '../../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import TimeWindowSelectorButton from '../../../../elem/chart/TimeWindowSelectorButton'
import AnalyteSelectorButton from '../../../../elem/chart/AnalyteSelectorButton'
import MatrixSelectorButton from '../../../../elem/chart/MatrixSelectorButton'
import ResetDropdown from '../../../../elem/chart/ResetSelector'
import ResetDropdownButton from '../../../../elem/chart/ResetDropdownButton'

export default () => {
    const {
        timeWindow,
        displayTimeWindowDropdown,
        toggleTimeWindowDropdown,
        displayAnalyteWindowDropdown,
        toggleAnalyteWindowDropdown,
        analyteList,
        toggleResetExpanded,
        resetExpanded,
        displayMatrixWindowDropdown,
        toggleMatrixWindowDropdown
    } = useContext(DataContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Analyte Chart</div>
            <div className="panelButtons">
                <ResetDropdownButton resetExpanded={resetExpanded} toggleResetExpanded={toggleResetExpanded} />
                {analyteList && analyteList.length ? (    
                    <Fragment>
                        <MatrixSelectorButton 
                            displayMatrixWindowDropdown={displayMatrixWindowDropdown}
                            toggleMatrixWindowDropdown={toggleMatrixWindowDropdown}
                        />
                        <AnalyteSelectorButton
                            displayAnalyteWindowDropdown={
                                displayAnalyteWindowDropdown
                            }
                            toggleAnalyteWindowDropdown={
                                toggleAnalyteWindowDropdown
                            }
                        />
                    </Fragment>
                ) : null}
                <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                />
            </div>
        </PanelHeader>
    )
}
