import React, {
    useEffect,
    useState,
    createContext,
    useContext,
    useCallback,
    useMemo,
} from 'react'
import get from 'lodash.get'
// import deepEqual from 'deep-equal'
import { deepEqual } from 'fast-equals'
import { useHistory, matchPath, useLocation } from 'react-router-dom'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
// import SkipToSampleResults from './form/SkipToSampleResults'
import urls from '../../../utils/constants/urls'
import { UserContext } from '../../wrappers/UserContext'
import actions from '../../../utils/submissions/actions'
// import merge from 'lodash.merge'
import cloneDeep from 'lodash.clonedeep'
import { decapitalizeText } from '../../../utils/stringUtils'
import ResolveNavGroups from './ResolveNavGroups'
import { getDefaultValuesFromSubmissionState } from '../../../utils/submissions/values'

const DataContext = createContext(null)

const UploadDataContextProvider = ({ children, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { user, userEmail } = useContext(UserContext)
    const [submissionState, setSubmissionState] = useState({})
    const [storedSubmissionState, setStoredSubmissionState] = useState({})
    const [activeAgency, setActiveAgency] = useState(null)
    const [primaryKey, setPrimaryKey] = useState(null)
    const [agencies, setAgencies] = useState(null)
    const [uploadConfig, setUploadConfig] = useState(null)
    const [formTypes, setFormTypes] = useState(null)
    const [navGroups, setNavGroups] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [activePanel, setActivePanel] = useState(null)
    const [errorState, setErrorState] = useState({})
    const [controlledPageSize, setControlledPageSize] = useState({})

    const [targetNavGroup, setTargetNavGroup] = useState({})
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [
        displayUnsavedChangesModal,
        setDisplayUnsavedChangesModal,
    ] = useState(false)
    const [displayDeleteModal, setDisplayDeleteModal] = useState({
        display: false,
        data: null,
    })

    const [continueAnyway, setContinueAnyway] = useState(false)
    const [
        displayContinueAnywayModal,
        setDisplayContinueAnywayModal,
    ] = useState(false)

    const [formMethods, setFormMethods] = useState({})
    const [formDirty, setFormDirty] = useState(false)
    const [tableData, setTableData] = useState({})
    const { API_URL } = config
    const history = useHistory()
    const { pathname } = useLocation()

    const isReview = useMemo(
        () =>
            !!matchPath(pathname, {
                path: '/submissions/:uploadType/:formType/review/:uploadId',
            }),
        [pathname]
    )

    const isForminProgress = useMemo(
        () =>
            !!matchPath(pathname, {
                path: '/submissions/:uploadType/:formType/:uploadId',
            }),
        [pathname]
    )

    const viewOnly = useMemo(
        () =>
            !!matchPath(pathname, {
                path: '/submissions/:uploadType/form/view/',
            })
    )

    const printableView = useMemo(
        () =>
            !!matchPath(pathname, {
                path: '/submissions/:uploadType/form/print/',
            })
    )

    const isEDD = useMemo(
        () => !!matchPath(pathname, { path: '/submissions/:uploadType/edd' })
    )

    const formUrl = useMemo(() => {
        const match = matchPath(pathname, { path: '/submissions/:uploadType' })
        return match && match.params && match.params.uploadType
            ? match.params.uploadType
            : null
    }, [pathname])

    const isSearchTabVisible = useMemo(() => {
        if (
            (submissionState.facility &&
                (submissionState.facility.FacilityId ||
                    submissionState.facility.uploadFacilityId)) ||
            (submissionState.project &&
                (submissionState.project.projectId ||
                    submissionState.project.uploadProjectId))
        ) {
            return false
        }
        return true
    }, [submissionState])

    const isECMCTabVisible = useMemo(() => 
        submissionState && submissionState.facility && submissionState.facility.associatedFacilityId
    , [submissionState])
    
    const isFormContactVisible = useMemo(() => 
        submissionState && !submissionState.contactInfoAdded
    , [submissionState])

    // form types
    const formType = useMemo(() =>
        formTypes ? formTypes.find(x => x.Link === formUrl) : {}
    )

    useEffect(() => {
        actions.getAgencies(authenticatedFetch, API_URL, setAgencies)
        actions.getFormTypes(authenticatedFetch, API_URL, setFormTypes)
    }, [])

    useEffect(() => {
        if (actions.isValidFormType(formType)) {
            actions.getSubmissionConfig(
                authenticatedFetch,
                API_URL,
                formType,
                setUploadConfig
            )
        }
    }, [formType])

    // upload config
    useEffect(() => {
        if (uploadConfig && formType) {
            const groupNames = [
                ...new Set(uploadConfig.map(x => x.SectionGroupName)),
            ]
            const configuredNavGroups = groupNames
                .filter(x => {
                    if (!isECMCTabVisible && x === 'Sample ECMC Facility Related Information') {
                        return false
                    }
                    return true
                })
                .map(x => {
                    const associatedConfig = uploadConfig.find(
                        y => y.SectionGroupName === x
                    )
                    const dependentConfig = uploadConfig.find(
                        y => y.DataAccessor === associatedConfig.Requires
                    )
                    return {
                        groupName: x,
                        requires: associatedConfig.Requires,
                        type: dependentConfig
                            ? dependentConfig.SectionType === 'Form'
                                ? 'object'
                                : 'array'
                            : 'object',
                        dataAccessor: associatedConfig.DataAccessor,
                        seq: associatedConfig.UploadSectionSeq,
                    }
                })
                .sort(x => x.seq)

            if (formType) {
                setNavGroups(
                    ResolveNavGroups(
                        formType,
                        configuredNavGroups,
                        isSearchTabVisible,
                        isFormContactVisible,
                        isReview,
                    )
                )
            }
        }
    }, [
        uploadConfig,
        isReview,
        viewOnly,
        formType,
        printableView,
        isSearchTabVisible,
        isECMCTabVisible,
        isFormContactVisible,
    ])

    const initializeState = data => {
        setStoredSubmissionState(data)
        setSubmissionState(data)
    }

    const resetState = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            if (formMethods && formMethods.reset && formMethods.getValues) {
                formMethods.reset()
            }
            const wow = cloneDeep(storedSubmissionState)
            setSubmissionState(wow)
            return resolve()
        })
    }, [storedSubmissionState, setSubmissionState, formMethods, uploadConfig])

    const createUpload = useCallback(
        agencyCode => {
            if (actions.isValidFormType(formType)) {
                actions.create(
                    authenticatedFetch,
                    API_URL,
                    history,
                    formType,
                    agencyCode,
                    user.profile.name
                )
            }
        },
        [formType, user]
    )

    const submitForValidation = useCallback(
        (data, force) => {
            return new Promise((resolve, reject) => {
                if (
                    (
                        data &&
                        !isSubmitting &&
                        Object.keys(data).length &&
                        formMethods &&
                        actions.isValidFormType(formType) &&
                        Object.keys(formMethods).length &&
                        !deepEqual(storedSubmissionState, data)
                    ) || force
                ) {
                    setIsSubmitting(true)
                    authenticatedFetch(
                        `${API_URL}/upload/${formType.APILink}`,
                        {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Headers':
                                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                            },
                            body: JSON.stringify(data),
                        }
                    )
                        .then(async response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .then(response => {
                            // if there is a submission, set the primaryKey to the associated primaryKey of the submission
                            if (
                                response &&
                                response.submission && response.submission[formType.PrimaryAccessor] 
                                && response.submission[formType.PrimaryAccessor][formType.UploadKeyField]
                            ) {
                                setPrimaryKey(
                                    response.submission[
                                        formType.PrimaryAccessor
                                    ][formType.UploadKeyField]
                                )
                            }

                            // if there are errors in this group, set the errors
                            // on the associated fields and prevent
                            if (
                                (response.errors &&
                                    Object.keys(response.errors).length) ||
                                (response.formErrors &&
                                    Object.keys(response.formErrors).length)
                            ) {
                                const errors = cloneDeep(response.errors)
                                const formErrors = cloneDeep(
                                    response.formErrors
                                )
                                // const allErrors = merge(errors, formErrors)
                                const errorKeys = new Set([
                                    ...Object.keys(formErrors),
                                    ...Object.keys(errors),
                                ])
                                const allErrors = [...errorKeys].reduce(
                                    (acc, curr) => {
                                        const fe = formErrors[curr]
                                            ? formErrors[curr]
                                            : []
                                        const e = errors[curr]
                                            ? errors[curr]
                                            : []
                                        const keyErrors = [...fe, ...e]
                                        return {
                                            ...acc,
                                            [curr]: keyErrors,
                                        }
                                    },
                                    {}
                                )
                                setErrorState(allErrors)
                                initializeState(response.submission)
                                setFormDirty(false)
                                // if we're forcing validation, then reject if there are severity = 'Error'
                                // but pass if all errors are severity = 'Warning'
                                if (force) {
                                    if (
                                        Object.keys(allErrors).filter(x =>
                                            allErrors[x].some(
                                                x => x.severity === 'Error'
                                            )
                                        ).length
                                    ) {
                                        return reject()
                                    }
                                }
                            } else {
                                if (
                                    formMethods &&
                                    Object.keys(formMethods).length
                                ) {
                                    formMethods.clearError()
                                }
                                setErrorState({})
                                initializeState(response.submission)
                                setFormDirty(false)
                                return resolve()
                            }
                            return resolve()
                        })
                        .catch(e => {
                            toast({
                                level: 'error',
                                message:
                                    'Submission: ' +
                                    (e.message
                                        ? e.message
                                        : 'Unable to connect to the server. Please try again later.'),
                            })
                            return reject()
                        })
                        .finally(() => {
                            setIsSubmitting(false)
                        })
                } else {
                    return resolve()
                }
            })
        },
        [
            storedSubmissionState,
            formMethods,
            uploadConfig,
            isSubmitting,
            formType,
        ]
    )

    useEffect(() => {
        if (!isEDD) {
            submitForValidation(submissionState).catch(() => {})
        }
    }, [submissionState, isEDD])

    const defaultValues = useMemo(() => {
        if (uploadConfig) {
            const d = getDefaultValuesFromSubmissionState(
                activePanel,
                uploadConfig,
                submissionState,
                printableView
            )
            console.log(d);
            return d
        }
        return {}
    }, [submissionState, uploadConfig, activePanel, printableView])

    const changeActivePanel = useCallback(
        newPanel => {
            if (formMethods && formMethods.reset && formMethods.getValues) {
                try {
                    formMethods.reset()
                } catch (e) {
                    console.log('e:', e)
                }
            }
            setActivePanel(newPanel)
        },
        [formMethods, setActivePanel]
    )

    const triggerValidation = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                checkForUnsavedChanges(group)
                    .then(async () => {
                        if (formMethods && Object.keys(formMethods).length) {
                            try {
                                await formMethods.triggerValidation()
                                const formErrors = get(
                                    formMethods.errors,
                                    group.dataAccessor
                                )
                                if (
                                    (formErrors &&
                                        Object.keys(formErrors).length !== 0) ||
                                    Object.keys(errorState).filter(x =>
                                        errorState[x].some(
                                            x => x.severity === 'Error'
                                        )
                                    ).length
                                ) {
                                    toast({
                                        level: 'error',
                                        message:
                                            'This form has validation errors. Please fix errors and save the form before submitting.',
                                        alert: true,
                                    })
                                    return reject()
                                }
                            } catch (e) {
                                return reject()
                            }
                        }
                        return resolve()
                    })
                    .catch(() => {
                        return reject()
                    })
            })
        },
        [formMethods, formDirty, errorState]
    )

    const checkForUnsavedChanges = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                // if there is a form, then we need to check it for dirty state
                if (
                    formMethods &&
                    Object.keys(formMethods).length &&
                    !viewOnly
                ) {
                    // if the form has been touched, we want to reject and display the unsaved changes
                    // banner
                    if (formDirty) {
                        setUnsavedChanges(true)
                        setDisplayUnsavedChangesModal(true)
                        if (group || activePanel) {
                            setTargetNavGroup(
                                group ? group : { groupName: activePanel }
                            )
                        }
                        return reject()
                    } else {
                        // otherwise, we hide the banner notification and
                        // resolve
                        setUnsavedChanges(false)
                        setDisplayUnsavedChangesModal(false)
                        return resolve()
                    }
                } else {
                    // otherwise, there are no unsaved changes and we continue + hide
                    // the notification banner
                    setUnsavedChanges(false)
                    setDisplayUnsavedChangesModal(false)
                    return resolve()
                }
            })
        },
        [formMethods, formDirty, viewOnly]
    )

    const submit = useCallback(() => {
        if (submissionState && actions.isValidFormType(formType)) {
            checkForUnsavedChanges()
                .then(async () => {
                    setIsSubmitting(true)
                    // then submit the form for validation
                    submitForValidation(submissionState, true)
                        .then(() => {
                            // if validation is successful, then submit
                            authenticatedFetch(
                                `${API_URL}/upload/submit?uploadId=${submissionState.uploadId}`, {
                                    method: 'PUT', 
                                    mode: 'cors',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Access-Control-Allow-Origin': '*',
                                        'Access-Control-Allow-Headers':
                                        'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                                    },
                                    body: JSON.stringify(userEmail ? userEmail : null),
                                }
                            )
                                .then(() => {
                                    history.push(urls.manageSampleResults)
                                })
                                .catch(e =>
                                    toast({
                                        level: 'error',
                                        message:
                                            `Submission Error: ` + e.message,
                                        alert: true,
                                    })
                                )
                        })
                        .catch(() => {
                            // otherwise, throw a toast that says they need to fix errors before continuing
                            toast({
                                level: 'error',
                                message:
                                    'This form has validation errors. Please fix errors and save the form before submitting.',
                                alert: true,
                            })
                        })
                        .finally(() => setIsSubmitting(false))
                })
                .catch(() => {})
        }
    }, [
        submissionState,
        activePanel,
        formMethods,
        formType,
        checkForUnsavedChanges,
        userEmail,
    ])

    const navigate = useCallback(
        group => {
            return new Promise((resolve, reject) => {
                // triggerValidation(group)
                checkForUnsavedChanges(group)
                    .then(() => resolve())
                    .catch(() => reject())
            })
        },
        [
            submissionState,
            formMethods,
            triggerValidation,
            checkForUnsavedChanges,
        ]
    )

    const discardUnsavedChanges = useCallback(() => {
        resetState()
            .then(() => {
                setFormDirty(false)
                changeActivePanel(targetNavGroup.groupName)
            })
            .then(setDisplayUnsavedChangesModal(false))
    }, [resetState, targetNavGroup])

    const returnToList = useCallback(() => {
        return new Promise((resolve, reject) => {
            const group = navGroups.find(x => x.groupName === activePanel)
            if (group) {
                if (
                    formMethods &&
                    Object.keys(formMethods).length &&
                    !viewOnly
                ) {
                    // if the form has been touched, we want to reject and display the unsaved changes
                    // banner
                    if (formDirty) {
                        setContinueAnyway(true)
                        setDisplayContinueAnywayModal(true)
                        return reject()
                    } else {
                        setContinueAnyway(false)
                        setDisplayContinueAnywayModal(false)
                        return resolve()
                    }
                } else {
                    setContinueAnyway(false)
                    setDisplayContinueAnywayModal(false)
                    return resolve()
                }
            } else {
                setContinueAnyway(false)
                setDisplayContinueAnywayModal(false)
                return resolve()
            }
        })
    }, [
        triggerValidation,
        navGroups,
        activePanel,
        formDirty,
        formMethods,
        formMethods.errors,
    ])

    const [
        displayBeforePageChangeModal,
        setDisplayBeforePageChangeModal,
    ] = useState(false)
    const [pageChangeAction, setPageChangeAction] = useState(false)

    const beforePageChange = useCallback(
        action => {
            if (formMethods && Object.keys(formMethods).length && !viewOnly) {
                // if the form has been touched, we want to reject and display the unsaved changes
                // banner
                if (formDirty) {
                    setDisplayBeforePageChangeModal(true)
                    setPageChangeAction(prev => action)
                } else {
                    setDisplayBeforePageChangeModal(null)
                    setPageChangeAction(null)
                    action()
                    return
                }
            } else {
                setDisplayBeforePageChangeModal(null)
                setPageChangeAction(null)
                action()
                return
            }
        },
        [
            triggerValidation,
            navGroups,
            activePanel,
            formDirty,
            formMethods,
            formMethods.errors,
        ]
    )

    const stampLatLong = useCallback(() => {
        if (formMethods && Object.keys(formMethods).length) {
            const group = navGroups.find(x => x.groupName === activePanel)
            if (group && group.dataAccessor) {
                const values = formMethods.getValues()
                const dataAccessor = group.dataAccessor
                if (
                    Object.keys(values).some(
                        x =>
                            x.includes('latitude83') &&
                            Object.keys(values).some(x =>
                                x.includes('longitude83')
                            )
                    )
                ) {
                    const lat = values[`${dataAccessor}.latitude83`]
                    const long = values[`${dataAccessor}.longitude83`]
                    authenticatedFetch(
                        `${API_URL}/upload/${formType.APILink}/geoStamp?latitude=${lat}&longitude=${long}&uploadId=${submissionState.uploadId}`
                    )
                        .then(async response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .then(response => {
                            Object.keys(response).forEach(key => {
                                const loweredKey = decapitalizeText(key)
                                const formInputName = Object.keys(values).find(
                                    v => {
                                        return (
                                            v.endsWith(loweredKey) ||
                                            v.endsWith(loweredKey + 'Select')
                                        )
                                    }
                                )
                                const value = response[key]
                                if (value !== null && value !== '') {
                                    if (formInputName) {
                                        if (formInputName.includes('Select')) {
                                            const configField = uploadConfig.find(
                                                x =>
                                                    x.ColumnName.toLowerCase() ===
                                                    key.toLowerCase()
                                            )
                                            const option = configField.Values.find(
                                                x =>
                                                    x.code
                                                        .toString()
                                                        .toLowerCase() ===
                                                    value
                                                        .toString()
                                                        .toLowerCase()
                                            )
                                            formMethods.setValue(
                                                formInputName,
                                                {
                                                    value: option.code,
                                                    label:
                                                        option.codedescription,
                                                    active: !!option.active,
                                                }
                                            )
                                        } else {
                                            formMethods.setValue(
                                                formInputName,
                                                value
                                            )
                                        }
                                    }
                                }
                            })
                        })
                        .catch(e => {
                            // console.log('tried to execute lat long stamp with bad values')
                        })
                }
            }
        }
    }, [submissionState, formMethods, navGroups, activePanel, formType])

    const [pageErrorText, setPageErrorText] = useState({})

    useEffect(() => {
        const o = Object.keys(errorState)
            .filter(x => x.includes('['))
            .reduce((acc, curr) => {
                const tableName = curr.split('[')[0]
                const idx = curr
                    .replace(tableName, '')
                    .replace('[', '')
                    .replace(']', '')
                const errors = errorState[curr]
                if (
                    errors.filter(
                        x => x.fieldName !== '' && x.severity === 'Error'
                    ).length
                ) {
                    if (acc[tableName]) {
                        return {
                            ...acc,
                            [tableName]: [...acc[tableName], idx],
                        }
                    } else {
                        return {
                            ...acc,
                            [tableName]: [idx],
                        }
                    }
                } else {
                    return acc
                }
            }, {})
        const textObject = Object.keys(o).reduce((acc, curr) => {
            const pagesWithErrors = o[curr]
                .map(x => Number(x))
                .map(x => Math.floor(x / controlledPageSize[curr]) + 1)
            return {
                ...acc,
                [curr]: `Record(s) have errors in the following pages: ${[
                    ...pagesWithErrors.sort(),
                ].join(', ')}`,
            }
        }, {})

        setPageErrorText(textObject)
    }, [controlledPageSize, errorState])

    const updateControlledPageSize = useCallback(
        (accessor, size) => {
            setControlledPageSize(prevCPS => ({
                ...prevCPS,
                [accessor]: size,
            }))
        },
        [setControlledPageSize]
    )

    const isMRDBMigration = useMemo(() => {
        return (
            submissionState &&
            submissionState.facility &&
            submissionState.facility.isMrdb
        )
    }, [submissionState])

    const isAuxilliaryTable = useMemo(() => {
        // auxilliary tables are used to edit additional information that for data in another tab, like for example attachments for a sample
        // vs. the actual creation of attachments. both reference the same key, but one of them should not allow deletions, since it
        // surfaces all entries in that other tab and allows for editing of certain parts of them. other parts are expected to be readonly
        return formType && formType.APILink === 'facility'  && activePanel &&  activePanel == 'Assign Attachments'
    }, [activePanel, formType])

    const isDisabledBasedOnMigration = useMemo(
        () => {
            // CWP2-212
            // there is another version of this function in parseColumns.js
            return isMRDBMigration &&
            formType &&
            activePanel &&
            formType.APILink === 'facility' &&
            !(
                activePanel === 'Sample Information' ||
                activePanel === 'Aquifer Information' || 
                // activePanel === 'Sample Point Alias Information' || 
                activePanel === 'Sample Result Information' ||
                activePanel === 'Batch Information' ||
                activePanel === 'Laboratory Quality Control Information' ||
                activePanel === 'Sample ECMC Facility Related Information'
            )
        }, [formType, submissionState, activePanel, isMRDBMigration]
    )

    return (
        <DataContext.Provider
            value={{
                agencies,
                uploadConfig,
                formType,
                formTypes,
                activeAgency,
                setActiveAgency,
                activePanel,
                setActivePanel,
                changeActivePanel,
                navGroups,
                setNavGroups,
                submissionState,
                storedSubmissionState,
                setSubmissionState,
                formMethods,
                setFormMethods,
                formDirty,
                setFormDirty,
                primaryKey,
                setPrimaryKey,
                initializeState,
                navigate,
                returnToList,
                submit,
                createUpload,
                resetState,
                isReview,
                viewOnly,
                tableData,
                unsavedChanges,
                displayUnsavedChangesModal,
                setDisplayUnsavedChangesModal,
                discardUnsavedChanges,
                continueAnyway,
                setContinueAnyway,
                setDisplayContinueAnywayModal,
                displayContinueAnywayModal,
                setDisplayBeforePageChangeModal,
                displayBeforePageChangeModal,
                beforePageChange,
                pageChangeAction,
                isSubmitting,
                setIsSubmitting,
                errorState,
                setErrorState,
                setTableData,
                submitForValidation,
                stampLatLong,
                updateControlledPageSize,
                pageErrorText,
                printableView,
                isForminProgress,
                displayDeleteModal,
                setDisplayDeleteModal,
                defaultValues,
                isMRDBMigration,
                isDisabledBasedOnMigration,
                isAuxilliaryTable
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(UploadDataContextProvider)
