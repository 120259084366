import React from 'react'
import ReactDOM from 'react-dom'
import VectorLayer from 'ol/layer/Vector'
import Vector from 'ol/source/Vector'
import { transformExtent } from 'ol/proj'
import { ZoomToExtent, Zoom, Attribution } from 'ol/control'
import { DragBox, Select, Draw } from 'ol/interaction'
import Polygon from 'ol/geom/Polygon'
import { FaSearchPlus, FaSearchMinus, FaGlobe } from 'react-icons/fa'
import { IconTooltip } from '../../../elem/Tooltip'
import { drawStyles } from './mapStyles'

const createLabel = (element, name, tooltip) => {
    const div = document.createElement('div')
    div.setAttribute('class', 'zoomToExtentDiv')
    if (name && tooltip) {
        div.setAttribute('data-for', name)
        div.setAttribute('data-tip', tooltip)
    }
    ReactDOM.render(element, div)
    return div
}

const getLayerTypes = () => {
    return {
        polygon: new VectorLayer({
            source: new Vector(),
            title: 'Polygon',
            visible: false,
        }),
    }
}

const getControls = layers => {
    const select = getSelectControl()
    const boundingBox = getBoundingBoxControl()
    const polygon = getPolygonControl(layers.polygon)
    return {
        select,
        boundingBox,
        polygon,
    }
}

const zoomControls = (extent) => {
    return [
        new Zoom({
            className: 'ol-zoom zoomWrapper',
            zoomInLabel: createLabel(
                <>
                    <FaSearchPlus />
                    <IconTooltip id={'zoom-in'} />
                </>,
                'zoom-in',
                'Zoom In'
            ),
            zoomInTipLabel: '',
            zoomOutLabel: createLabel(
                <>
                    <FaSearchMinus />
                    <IconTooltip id={'zoom-out'} />
                </>,
                'zoom-out',
                'Zoom Out'
            ),
            zoomOutTipLabel: '',
        }),
        new ZoomToExtent({
            extent: transformExtent(extent, 'EPSG:4326', 'EPSG:3857'),
            label: createLabel(
                <>
                    <FaGlobe />
                    <IconTooltip id={'zoom-to-extent'} />
                </>,
                'zoom-to-extent',
                'Zoom to Full Extent'
            ),
            tipLabel: '',
            className: 'ol-zoom-extent zoomToExtentWrapper',
        }),
        new Attribution({
            className: 'ol-attribution attributionWrapper',
        }),
    ]
}
const getSelectControl = () => {
    const control = new Select({
        filter: f => {
            // only allow points to be selected
            return f.getGeometry().getType() === 'Point'
        },
        style: e => {
            if (e.get('selected')) {
                return drawStyles.pointerStylesHighlighted
            } else {
                return drawStyles.pointerStylesSelected
            }
        },
        hitTolerance: 12
    })

    const on = (source, updateSelectedFeatures) => {
        const mouseDownKey = control.on('select', function(e) {
            var tolerance = 15
            if (e.selected.length) {
                const map = e.mapBrowserEvent.map
                const coordinates = e.selected[0].getGeometry().flatCoordinates
                const targetPixel = map.getPixelFromCoordinate(coordinates)

                const pixel1 = [
                    targetPixel[0] - tolerance / 2,
                    targetPixel[1] - tolerance / 2,
                ]
                const pixel2 = [
                    targetPixel[0] - tolerance / 2,
                    targetPixel[1] + tolerance / 2,
                ]
                const pixel3 = [
                    targetPixel[0] + tolerance / 2,
                    targetPixel[1] + tolerance / 2,
                ]
                const pixel4 = [
                    targetPixel[0] + tolerance / 2,
                    targetPixel[1] - tolerance / 2,
                ]

                const point1 = map.getCoordinateFromPixel(pixel1)
                const point2 = map.getCoordinateFromPixel(pixel2)
                const point3 = map.getCoordinateFromPixel(pixel3)
                const point4 = map.getCoordinateFromPixel(pixel4)

                const polygon = new Polygon([[point1, point2, point3, point4]])
                const newFilterData = source
                    .getFeatures()
                    .filter(feature =>
                        feature.get('displayed') && polygon.intersectsExtent(
                            feature.getGeometry().getExtent()
                        )
                    )

                updateSelectedFeatures(newFilterData)
            }
        })
        const keys = [mouseDownKey]
        return keys
    }

    return {
        active: false,
        control,
        on,
    }
}

const getBoundingBoxControl = () => {
    const control = new DragBox()

    const on = (source, updateSelectedFeatures) => {
        const boxEndKey = control.on('boxend', () => {
            const extent = control.getGeometry().getExtent()
            const newFilterData = source.getFeaturesInExtent(extent).filter(x => x.get('displayed'))
            updateSelectedFeatures(newFilterData)
        })
        const keys = [boxEndKey]
        return keys
    }

    return {
        active: false,
        control,
        on,
    }
}

const getPolygonControl = layer => {
    const control = new Draw({
        type: 'Polygon',
        source: layer.getSource(),
        layer,
        style: drawStyles.polygonStyles,
    })

    const on = (source, updateSelectedFeatures) => {
        const drawEndKey = control.on('drawend', e => {
            const polygon = e.feature.getGeometry()
            const newFilterData = source
                .getFeatures()
                .filter(feature =>
                    feature.get('displayed') && polygon.intersectsExtent(feature.getGeometry().getExtent())
                )
            updateSelectedFeatures(newFilterData)
        })
        const keys = [drawEndKey]
        return keys
    }
    return {
        active: false,
        control,
        layer,
        on,
    }
}

const getDisplayStyleProps = (e, pixel) => {
    // horizontal edge detection
    const tooltipWidth = 400
    const windowWidth = e.pointerEvent.view.innerWidth
    const clientX = e.originalEvent.clientX
    const mapWidth = e.originalEvent.currentTarget.scrollWidth
    const tooltipRightPxValue = clientX + tooltipWidth
    const exceedsRightEdge = tooltipRightPxValue > windowWidth
    const horizontalProps = exceedsRightEdge
        ? { right: mapWidth - pixel[0] + 'px' }
        : { left: pixel[0] + 'px' }

    // vertical edge detection
    const tooltipHeight = 270
    const tooltipYTopOffset = 100
    const tooltipYBottomOffset = tooltipHeight - tooltipYTopOffset
    const pixelY = pixel[1]
    
    // top edge
    const topDelta = pixelY - tooltipYTopOffset
    const exceedsTopEdge = topDelta < 0

    // bottom edge
    const bottomPixel = e.originalEvent.currentTarget.clientHeight
    const bottomDelta = pixelY - 10 + tooltipYBottomOffset - bottomPixel
    const exceedsBottomEdge = bottomDelta > 0
    
    const verticalProps = exceedsTopEdge
        ? { top: pixel[1] - (tooltipYTopOffset + topDelta) + 10 + 'px' }
        : exceedsBottomEdge ? 
            { top: pixel[1] - tooltipYTopOffset - bottomDelta } 
            : { top: pixel[1] - tooltipYTopOffset + 'px' }

    const styleProps = {
        ...horizontalProps,
        ...verticalProps,
    }
    return styleProps
}

const displayPopup = async (e, map, setTooltipState, fetchTooltipData) => {
    const pixel = map.getEventPixel(e.originalEvent)

    // unhighlight the previous feature
    const previousFeature = map.get('currentFeature')
    if (previousFeature) {
        previousFeature.set('highlighted', 0)
    }

    const feature = map.forEachFeatureAtPixel(pixel, function(feature, layer) {
        return feature
    }, { hitTolerance: 12})

    if (feature) {
        // offset the tooltip around the pixel values of the feature
        const featurePixel = map.getPixelFromCoordinate(feature.getGeometry().getCoordinates())
        const styleProps = getDisplayStyleProps(e, featurePixel)

        // fetch the tooltip data if we have a new clearinghouse number
        const facilityID = feature.get('FacilityID')
        feature.set('highlighted', 1)
        if (map.get('currentFacilityID') !== facilityID) {
            map.set('currentFacilityID', facilityID)
            map.set('currentFeature', feature)
            fetchTooltipData(facilityID)
        }
        setTooltipState({
            ...styleProps,
            facilityID,
        })
    } else {
        // if we're not over a feature,
        // set clearinghouse number to null
        setTooltipState(prevState => ({
            ...prevState,
            facilityID: null,
        }))
    }
}
export { getControls, getLayerTypes, zoomControls, displayPopup }
