import AliasForm from './form/AliasForm'
import ReviewSubmission from './form/ReviewSubmission'
import SamplePointSearchForm from './form/facilitySearch/SamplePointSearch'
import UploadAttachments from './form/UploadAttachments'
import SearchForm from './form/SearchForm'
import FacilityAssignment from './form/facilityAssignment/FacilityAssignment'
import FormContactInfo from './form/formContactInfo/FormContactInfo'

export default (formType, configuredNavGroups, isSearchTabVisible, isFormContactVisible, isReview) => {
    const primaryAccessor = formType.PrimaryAccessor

    const aliasTab = {
        groupName: `${formType && formType.FormType} Alias Information`,
        requires: null,
        type: 'component',
        props: { formType },
        component: AliasForm,
        dataAccessor: `${primaryAccessor}Alias`
    }

    const reviewTab = {
        groupName: 'Review',
        requires: null,
        type: 'component',
        component: ReviewSubmission,
        dataAccessor: primaryAccessor,
    }

    const genericSearchTab = {
        groupName: `${formType.FormType} Search`,
        requires: null,
        type: 'component',
        component: SearchForm,
        props: { formType },
        dataAccessor: primaryAccessor,
    }

    const samplePointSearchTab = {
        groupName: 'Sample Point Search',
        requires: null,
        type: 'component',
        props: { formType },
        component: SamplePointSearchForm,
        dataAccessor: primaryAccessor
    }

    const uploadAttachmentTab = {
        groupName: `Upload Attachments`,
        requires: null,
        type: 'component',
        props: { formType },
        component: UploadAttachments,
        dataAccessor: formType.PrimaryAccessor
    }
    
    const samplePointAssignmentTab = {
        groupName: `Sample Point Assignments`,
        requires: 'project',
        type: 'component',
        component: FacilityAssignment,
        props: {
            formType
        },
    }

    const formContactInfo = {
        groupName: `Form Contact Information`,
        requires: null,
        type: 'component',
        component: FormContactInfo,
        props: {
            formType,
        },
    }
    
    let allNavGroups = configuredNavGroups
    let searchForm
    switch (formType.APILink) {
        case 'facility':
            searchForm = samplePointSearchTab
            allNavGroups = [
                aliasTab,
                ...allNavGroups
            ]
            const attachmentIndex = allNavGroups.findIndex(group => group.groupName === 'Assign Attachments')
            allNavGroups.splice(attachmentIndex, 0, uploadAttachmentTab)
            break
        case 'project':
            searchForm = genericSearchTab
            allNavGroups = [
                aliasTab,
                ...allNavGroups,
                samplePointAssignmentTab
            ]
            break
        default:
            searchForm = samplePointSearchTab
            allNavGroups = [
                aliasTab,
                uploadAttachmentTab,
                ...allNavGroups
            ]
            break
    }
    
    if (isSearchTabVisible) {
        allNavGroups = [searchForm, ...allNavGroups]
    }
    if (formType.APILink === `facility` && isFormContactVisible) {
        allNavGroups = [formContactInfo, ...allNavGroups]
    }
    if (isReview) {
        allNavGroups = [ ...allNavGroups, reviewTab]
    }
    return allNavGroups
} 