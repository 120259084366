import React from 'react'
import { FaTimes, FaInfoCircle } from 'react-icons/fa'

import Tooltip from '../../Tooltip'
import { PanelStyleContext } from '../../panel/PanelStyleContext'
import deepEqual from 'deep-equal'

const InputFieldWrapper = ({ helper, setValue, errors, clearError, inputProps }) => {
    const isFieldArrayInput = !isNaN(inputProps.arrayPosition)
    const inputName = isFieldArrayInput ? `${inputProps.name}[${inputProps.arrayPosition}]`: inputProps.name
    const error = errors && errors[inputProps.name] ? isFieldArrayInput ? errors[inputProps.name][inputProps.arrayPosition] : errors[inputProps.name] : null
    return (
        <PanelStyleContext.Consumer>
            {styleContext => (
                <div
                    className={`control is-expanded ${
                        helper ? 'has-icons-left' : ''
                    } has-icons-right ${styleContext.clearInputFieldClass}` }
                >
                    <input {...inputProps} name={inputName} />
                    {helper && (
                        <span
                            data-tip={`${helper}`}
                            data-for={inputProps.name}
                            className="icon is-left formHelper"
                        >
                            <FaInfoCircle />
                            <Tooltip id={inputProps.name} className={styleContext.tooltipClass} />
                        </span>
                    )}
                    <span
                        className="icon is-right"
                        onClick={() => {
                            setValue(inputName, null)
                            clearError(inputProps.name)
                        }}
                    >
                        <FaTimes />
                    </span>
                    <div className="help is-danger inputErrors">
                        {error && <span className="inputErrorText">{error.message}</span>}
                    </div>
                </div>

            )}
        </PanelStyleContext.Consumer>
    )
}

const InputComponent = ({
    filterName,
    type,
    errors,
    clearError,
    initialFilterValues,
    registerParams,
    register,
    unregister,
    minValue,
    maxValue,
    maxLength,
    between,
    setValue,
    fieldName,
    helper,
}) => {
    // construct input props
    const inputProps = {
        name: filterName,
        type: type,
        className: `input is-small formInput ${(errors && errors[filterName] && !(deepEqual(errors[filterName], {}) || deepEqual(errors[filterName], new Array(2)))) ? 'is-danger' : ''}`,
        defaultValue: initialFilterValues[0],
        ref: register(registerParams),
    }

    if (type === 'number') {
        if (minValue) {
            inputProps.min = minValue
        }
        if (maxValue) {
            inputProps.max = maxValue
        }
        if (filterName === 'TownshipValue' || filterName === 'RangeValue') {
            inputProps.step = '0.5'
        }
    }
    // if (maxLength) {
    //     inputProps.maxLength = maxLength
    // }
    if (between) {
        unregister(`${filterName}`)
        const firstInputProps = {
            ...inputProps,
            arrayPosition: 0,
            defaultValue: initialFilterValues[0],
        }
        const secondInputProps = {
            ...inputProps,
            arrayPosition: 1,
            defaultValue: initialFilterValues[1],
        }
        return (
            <div className="betweenInputsWrapper">
                <InputFieldWrapper
                    helper={helper}
                    setValue={setValue}
                    clearError={clearError}
                    errors={errors}
                    inputProps={firstInputProps}
                />
                <span className="betweenAndText">AND</span>
                <InputFieldWrapper
                    helper={helper}
                    setValue={setValue}
                    clearError={clearError}
                    errors={errors}
                    inputProps={secondInputProps}
                />
            </div>
        )
    } else {
        unregister(`${filterName}[0]`)
        unregister(`${filterName}[1]`)
        return (
            <InputFieldWrapper
                helper={helper}
                setValue={setValue}
                errors={errors}
                clearError={clearError}
                inputProps={inputProps}
            />
        )
    }
}

export default InputComponent
