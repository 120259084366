import React from 'react'

import { UpArrow, DownArrow } from '../Arrows'

export default ({
    toggleMatrixWindowDropdown,
    displayMatrixWindowDropdown,
    inHeader=true
}) => {
    return (
        <div
            className="panelButton"
            onClick={() =>
              toggleMatrixWindowDropdown(!displayMatrixWindowDropdown)
            }
        >
            <span className="panelTimeOptionText">Matrix</span>
            <span className="panelTimeOptionDropdown">
              {displayMatrixWindowDropdown ? <UpArrow className={`${inHeader ? 'is-white' : ''}`}/> : <DownArrow className={`${inHeader ? 'is-white' : ''}`}/>}
            </span>
        </div>
    )
}
