import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const MapLayerLegendItem = ({ styleObj }) => {
    const style = JSON.parse(styleObj)
    const { imgSrc } = style
    if (imgSrc) {
        return <div className="mapLayerLegendImage mapLayerLegendIcon"><img src={imgSrc} className="mapLayerLegendSymbol" alt="map-layer-symbol"/></div>
    }
    return <div
            className="mapLayerLegendItem"
            style={{
                backgroundColor: style.fillColor,
                border: style.strokeColor
                    ? `${style.strokeDash ? 'dashed' : 'solid'} ${style.strokeWidth  ? style.strokeWidth + 'px' : '1px'} ${style.strokeColor}`
                    : '',
                borderRadius: style.circle ? '10px' : null,
                height: style.size ? style.size : '18px',
                width: style.size ? style.size : '18px',
                minHeight: style.size ? style.size : '18px',
                minWidth: style.size ? style.size : '18px'
            }}
        />
}

const MapLayerLegend = ({ styleArray, type, expanded, shape, sourceUrl }) => {
    const [style, setStyle] = useState(JSON.parse(styleArray))
    const borderRadius = shape === 'circle' ? '10px' : null
    useEffect(() => {
        const fetchLegend = async (s) => {
            const url = `${sourceUrl}/legend?f=json`
            fetch(url)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    if (response && response.layers) {
                        if (s.layerId === null || isNaN(s.layerId)) {
                            throw new Error('No layer id was specified in the layer style configuration')
                        } else {
                            const legendData = response.layers.find(x => x.layerId === s.layerId)
                            if (legendData && legendData.legend) {
                                const legendArray = legendData.legend
                                setStyle(legendArray.map(x => ({
                                    label: x.label,
                                    fillColor: `data:image/gif;base64,${x.imageData}`,
                                    fetchLegend: true
                                })))
                            }
                        }
                    } else {
                        throw new Error('No layers were returned with the legend request')
                    }
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message: 'Legend Fetch: ' + e.message
                    })
                })
        }
        const s = JSON.parse(styleArray)
        if (s && !isNaN(s.layerId) && s.fetchLegend && sourceUrl ) {
            fetchLegend(s)
                
        }
    }, [styleArray, sourceUrl])

    if (!expanded) {
        return null
    }

    return (
        <div className="mapLayerLegendWrapper">
            {style && Array.isArray(style) && style.map(s => (
                <div className="mapLayerLegendItemWrapper">
                    {(s.fillColor && (s.fillColor.includes('.png') || s.fillColor.includes('.svg'))) || (s.fetchLegend) ? (
                            <img className="mapLayerLegendImage" alt="legend item" src={s.fillColor} style={{color: 'black'}}/>
                        ) : (
                            <div
                                className="mapLayerLegendItem"
                                style={{
                                    backgroundColor: s.fillColor,
                                    borderRadius,
                                    border: s.strokeColor
                                        ? `solid 1px ${s.strokeColor}`
                                        : '',
                                }}
                            />
                        )}
                    <div className="mapLayerLegendItemText is-size-7">
                        {type === 'THEME' && s.range}
                        {type === 'COLORMAP' && s.label}
                        {type === 'SPECTRUM' && s.label}
                        {type === 'IMAGE' && s.label}
                    </div>
                </div>
            ))}
        </div>
    )
}

export default MapLayerLegendItem
export { MapLayerLegend, MapLayerLegendItem }
