import React from 'react'

import DataContextProvider from './DataContext'
import Panel from '../../../../elem/panel/Panel'
import { aggregatePanel as panelClassConfig } from '../../../../../utils/styles/panelStyles'
// import { AppStateContext } from '../AppStateContext'
import AggregateHeader from './AggregateHeader'
import AnalyteDropdown from './AnalyteDropdown'
import AggregateChart from './AggregateChart'
import TimeWindowDropdown from './TimeWindowDropdown'
import ResetDropdown from './ResetDropdown'
import MatrixDropdown from './MatrixDropdown'

const Aggregate = ({ width }) => {
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className="explorerWrapper">
                <DataContextProvider>
                    <AggregateHeader />
                    <ResetDropdown />
                    <MatrixDropdown />
                    <AnalyteDropdown />
                    <TimeWindowDropdown />
                    <AggregateChart />
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Aggregate