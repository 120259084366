
import React from 'react'
import Select from 'react-select'
import SelectStyles from '../../../elem/form/SelectStyles'

const ToggleComponent = ({ onDropDownChange, formType, }) => {
    const ogFacilityMenu = (<>
        <div className="field-label">
            <label className="label">Is Sample Point Associated with an O&G Facility?</label>
        </div>
        <div className="field-body">
            <div className="field">
                <Select
                    className="select is-multiple is-fullwidth reactSelect"
                    classNamePrefix="reactSelect"
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]}
                    styles={SelectStyles}
                    menuPlacement="auto"
                    onChange={data => onDropDownChange(null, data.value)}
                    defaultValue={[{ value: true, label: 'Yes'}]}
                />
            </div>
        </div>
    </>)

    const newToWaterStarMenu = (<>
        <div className="field-label">
            <label className="label">New {`${formType.FormType}`} to WaterSTAR?</label>
        </div>
        <div className="field-body">
            <div className="field">
                <Select
                    className="select is-multiple is-fullwidth reactSelect"
                    classNamePrefix="reactSelect"
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]}
                    styles={SelectStyles}
                    menuPlacement="auto"
                    onChange={data => onDropDownChange(data.value, null)}
                    defaultValue={[{ value: true, label: 'Yes'}]}
                />
            </div>
        </div>
    </>)

    return (
        <div className={`columns mr-2`}>
            {formType && formType.APILink === 'facility' && (<>
                <div className={`mb-3 column is-6 field is-horizontal is-align-items-center`}>
                    {newToWaterStarMenu}
                </div>
                <div className={`column is-6 field is-horizontal is-align-items-center`}>
                    {ogFacilityMenu}
                </div>            
            </>)}
            {formType && formType.APILink === 'project' && (<>
                {newToWaterStarMenu}
            </>)}

        </div>
    )
}

export default ToggleComponent