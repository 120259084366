import React, { useContext, useMemo, useCallback, } from 'react'
import { Controller } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import get from 'lodash.get'
import { FaAsterisk } from 'react-icons/fa'

import SelectStyles from '../../../elem/form/SelectStyles'
import { DataContext } from '../DataContext'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import {
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    isChanged,
} from '../../../../utils/submissions/values'
import { Tooltip } from '../../../elem/Tooltip'
import FieldErrors from './FieldErrors'

const mapValuesToOptions = (values, fieldAccessorMap) => {
    if (!values) {
        return []
    }
    const options = values
        .filter(v => v[fieldAccessorMap.value] !== '')
        .map(v => {
            return {
                label: v[fieldAccessorMap.label],
                value:
                    fieldAccessorMap.type === 'number'
                        ? parseFloat(v[fieldAccessorMap.value])
                        : v[fieldAccessorMap.value],
                type: fieldAccessorMap.type,
                active: v[fieldAccessorMap.active],
            }
        })
    return options
}

const onChange = ([selected]) => {
    // React Select return object instead of value for selection
    return { value: selected }
}

const Select = ({
    name,
    fieldName,
    values,
    control,
    helper,
    errors,
    fieldAccessorMap,
    formWidth,
    dataAccessor,
    displayLabel,
    rowIdx,
    required,
    registerParams,
    columnWidth,
    disabled,
    watch,
}) => {
    const {
        submissionState,
        activePanel,
        viewOnly,
        errorState,
    } = useContext(DataContext)
    const c = useContext(ExistingDataContext)
    const uploadChanges = c ? c.uploadChanges : null

    const options = useMemo(() => {
        return mapValuesToOptions(values, fieldAccessorMap)
    }, [values, fieldAccessorMap, submissionState])

    const changed = useMemo(
        () =>
            isChanged(
                uploadChanges,
                dataAccessor,
                name,
                rowIdx,
                submissionState
            ),
        [uploadChanges, submissionState, dataAccessor, name, rowIdx]
    )

    const inputName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}Select`

    const formError =
        errors && get(errors, inputName) ? get(errors, inputName) : null

    const warning = useMemo(() => {
        return getWarningFromErrorState(errorState, name, rowIdx, dataAccessor)
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const apiError = useMemo(() => {
        return getApiErrorFromErrorState(
            errorState,
            name,
            rowIdx,
            dataAccessor,
            formError
        )
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const displayWindowSizeIncrement = 200
    // const [displayWindowSize, setDisplayWindowSize] = useState(displayWindowSizeIncrement)
    // const reactSelectKey = useMemo(() => `react-select-${displayWindowSize}`, [displayWindowSize])

    // const onMenuScrollToBottom = useCallback(() => {
    //     const newDisplayWindowSize = displayWindowSize + displayWindowSizeIncrement
    //     console.log('arf')
    //     setDisplayWindowSize(newDisplayWindowSize)
    // }, [displayWindowSize, setDisplayWindowSize])

    const loadOptions = useCallback((inputValue, callback) => {
        if (inputValue) {
            const filteredOptions = options.filter(option => 
                option && option.label && option.label.toString().toLowerCase().includes(inputValue.toString().toLowerCase())
            ).slice(0, displayWindowSizeIncrement)
            callback(filteredOptions)
        } else {
            callback(options.slice(0, displayWindowSizeIncrement))
        }
    }, [options])

    return (
        <div
            className={`column ${
                formWidth === 'full' ? 'is-one-third' : 'is-full'
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field is-horizontal">
                    {displayLabel && (
                        <div className="field-label is-small">
                            <label className="label">
                                <span>{fieldName}</span>
                                {required ? (
                                    <span className="requiredStar has-text-danger">
                                        {<FaAsterisk />}
                                    </span>
                                ) : null}{' '}
                            </label>
                        </div>
                    )}
                    <div className="field-body">
                        <div className={`field ${helper ? 'has-addons' : ''}`}>
                            <div
                                className={`control is-expanded`}
                                data-tip={`${watch(`${inputName}`) ? watch(`${inputName}`).label : ''}`}
                                data-for={inputName}
                            >
                                    <Controller
                                        as={
                                            <AsyncSelect
                                                className={`select is-multiple is-small is-multiple is-fullwidth reactSelect ${
                                                    changed
                                                        ? 'changedSelect'
                                                        : ''
                                                }`}
                                                classNamePrefix="reactSelect"
                                                loadOptions={(inputValue, callback) => loadOptions(inputValue, callback)}
                                                // onMenuScrollToBottom={() => {
                                                //     onMenuScrollToBottom()
                                                // }
                                                // }
                                                // key={reactSelectKey}
                                                defaultOptions
                                                // options={options}
                                                styles={SelectStyles}
                                                width={
                                                    columnWidth
                                                        ? `${columnWidth}px`
                                                        : null
                                                }
                                                isOptionDisabled={option =>
                                                    !option.active
                                                }
                                                menuPlacement="auto"
                                                isClearable
                                                isDisabled={viewOnly || disabled}
                                                menuPosition={'fixed'} 
                                            />
                                        }
                                        name={inputName}
                                        control={control}
                                        onChange={onChange}
                                        rules={registerParams}
                                    />
                                <Tooltip
                                    id={inputName}
                                    extraClass={`tooltip-2`}
                                />
                                <FieldErrors
                                    formError={formError}
                                    warning={warning}
                                    apiError={apiError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Select
