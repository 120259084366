import React, { useContext, useMemo } from 'react'
import DatePicker from 'react-datepicker'
import { FaInfoCircle, FaTimes } from 'react-icons/fa'
import get from 'lodash.get'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Tooltip from '../../../elem/Tooltip'
import { DataContext } from '../DataContext'
import {
    getApiErrorFromErrorState,
    getWarningFromErrorState,
    isChanged,
} from '../../../../utils/submissions/values'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import FieldErrors from './FieldErrors'
import { Controller } from 'react-hook-form'

dayjs.extend(utc)

const formatDate = value =>
    value
        ? dayjs(value)
              .format('MM/DD/YYYY')
              .toString()
        : null

const formatDateString = value =>
    value
        ? dayjs(value)
              .format('MM/DD/YYYY')
              .toString()
        : ''

const getDate = value => (value ? new Date(value) : null)

const DateSelect = ({
    name,
    fieldName,
    helper,
    register,
    registerParams,
    setValue,
    errors,
    formWidth,
    watch,
    dataAccessor,
    displayLabel,
    rowIdx,
    disabled,
    control,
    printable = false,
}) => {
    const { submissionState, activePanel, viewOnly, errorState } = useContext(
        DataContext
    )
    const c = useContext(ExistingDataContext)
    const uploadChanges = c ? c.uploadChanges : null

    const filterName = `${dataAccessor}${
        typeof rowIdx !== 'undefined' ? `[${rowIdx}]` : ''
    }.${name}DateSelect`

    const changed = useMemo(
        () =>
            isChanged(
                uploadChanges,
                dataAccessor,
                name,
                rowIdx,
                submissionState
            ),
        [uploadChanges, dataAccessor, name, rowIdx, submissionState]
    )

    // construct dateSelect props
    const dateSelectProps = {
        name: filterName,
        className: `input is-small ${changed ? 'is-link' : ''}`,
        ref: register,
    }

    const formError =
        errors && get(errors, dateSelectProps.name)
            ? get(errors, dateSelectProps.name)
            : null

    const warning = useMemo(() => {
        return getWarningFromErrorState(errorState, name, rowIdx, dataAccessor)
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    const apiError = useMemo(() => {
        return getApiErrorFromErrorState(
            errorState,
            name,
            rowIdx,
            dataAccessor,
            formError
        )
    }, [errorState, name, activePanel, rowIdx, formError, dataAccessor])

    return (
        <div
            className={`column ${
                formWidth === 'full' ? 'is-one-third' : 'is-full'
            } no-vertical-padding formInputWrapper`}
        >
            <div className="formInputLayout">
                <div className="field is-horizontal">
                    {displayLabel && (
                        <div className="field-label is-small">
                            <label className="label">{fieldName}</label>
                        </div>
                    )}
                    <div className="field-body">
                        <div className={`field has-addons`}>
                            {!printable && (
                                <div
                                    className={`control is-expanded has-icons-right `}
                                    data-tip={`${formatDateString(
                                        watch(dateSelectProps.name)
                                    )}`}
                                    data-for={dateSelectProps.name}
                                >
                                    <Tooltip
                                        id={dateSelectProps.name}
                                        className={`tooltip-2`}
                                    />
                                    {helper && (
                                        <div
                                            data-tip={`${helper}`}
                                            data-for={`${dateSelectProps.name}-helper`}
                                            className="control formHelper"
                                        >
                                            <span className="button is-static is-small">
                                                <FaInfoCircle />
                                                <Tooltip
                                                    id={`${dateSelectProps.name}-helper`}
                                                />
                                            </span>
                                        </div>
                                    )}
                                    {!(viewOnly) ? (
                                        <Controller
                                            as={
                                                <DatePicker
                                                    className={`input is-small ${
                                                        changed
                                                            ? 'is-changed'
                                                            : ''
                                                    }`}
                                                    maxDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"
                                                    // selected={date}
                                                    selected={getDate(
                                                        watch(
                                                            dateSelectProps.name
                                                        )
                                                    )}
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    yearDropdownItemNumber={150}
                                                    scrollableYearDropdown
                                                    autoComplete="off"
                                                    disabled={disabled}
                                                    onChange={([value]) => {
                                                        return formatDate(value)
                                                    }}
                                                />
                                            }
                                            name={`${dateSelectProps.name}`}
                                            rules={registerParams}
                                            control={control}
                                        />
                                    ) : (
                                        <input
                                            disabled
                                            className={`input is-small ${
                                                changed ? 'is-changed' : ''
                                            }`}
                                            name={filterName}
                                            ref={register(registerParams)}
                                        />
                                    )}
                                    {!(viewOnly || disabled) ? (
                                        <span
                                            className="icon is-right is-size-7"
                                            onClick={() => {
                                                setValue(dateSelectProps.name, null)
                                            }}
                                        >
                                            <FaTimes />
                                        </span>
                                    ) : null}
                                    <FieldErrors
                                        formError={formError}
                                        warning={warning}
                                        apiError={apiError}
                                    />
                                </div>
                            )}
                            {printable && (
                                <div
                                    className={`control is-expanded has-icons-right `}
                                >
                                    <input
                                        disabled
                                        className={`input is-small is-printable`}
                                        name={filterName}
                                        ref={register(registerParams)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateSelect