import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { FormContext, useForm } from 'react-hook-form'
import Form from '../FormPanel'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { DataContext } from '../../DataContext'
import withConfig from '../../../../wrappers/withConfig'
import { ExistingDataContext } from '../ExistingSubmissionDataContext'
import { isChanged, transformDBValuesToFormValues } from '../../../../../utils/submissions/values'
import Spinner, { SimpleSpinner } from '../../../../elem/Spinner'
import { toast } from 'react-toastify'
import { setReactiveValues } from '../../../../../utils/form/reactiveValues'
import { getRequiredText, shouldDisplayRequiredText } from '../../../../../utils/submissions/helperText'
import SaveButton from '../SaveButton'

const FormContactInfo = ({ formType, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const {
        activePanel,
        submissionState,
        setFormMethods,
        setFormDirty,
        setSubmissionState,
        printableView: printable,
        errorState,
        isSubmitting,
        setIsSubmitting,
    } = useContext(DataContext)
    const panelName = 'Form Contact Information'
    const [loadingConfig, setLoadingConfig] = useState(false)
    const { API_URL } = config
    const [formContactConfig, setFormContactConfig] = useState([])

    useEffect(() => {
        setLoadingConfig(true)
        authenticatedFetch(`${API_URL}/upload/${formType.APILink}/formContactConfig`)
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setFormContactConfig(response.uploadConfig)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Submission Config: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(() => setLoadingConfig(false))
    }, [])

    const defaultValues = useMemo(() => {
        if (formContactConfig) {
            let relatedConfig = formContactConfig
            const subSections = [
                ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
            ]
            let df = {
                upload: {}
            }
            subSections.reduce((acc, section) => {
                const sectionData = relatedConfig.filter(
                    x => x.UploadSectionGroupName === section
                )
                sectionData.reduce((accc, row) => {
                    df.upload = transformDBValuesToFormValues(
                        submissionState,
                        relatedConfig
                    )
                    return accc
                }, {})
                return acc
            }, {})
    
            return df
        } else {
            return {}
        }
    }, [formContactConfig, submissionState])

    const formMethods = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
    })
    const { handleSubmit, formState, watch, } = formMethods
    const formValues = watch()
    const { dirty, } = formState

    const currentPageData = useMemo(() => {
        if (panelName || printable) {
            let relatedConfig = formContactConfig
            const subSections = [
                ...new Set(relatedConfig.map(x => x.UploadSectionGroupName)),
            ]
            return subSections.map((section, idx) => {
                const sectionData = relatedConfig.filter(
                    x => x.UploadSectionGroupName === section
                )
                const exampleRow = sectionData.length && sectionData[0]
                return {
                    config: sectionData,
                    type: exampleRow.SectionType,
                    accessor: exampleRow.DataAccessor,
                    title: exampleRow.UploadSectionGroupName,
                    subtitle: null,//exampleRow.UploadSectionGroupDescription,
                    oneRowRequired: exampleRow.OneRowRequired,
                    printable,
                    key: `form-component-${idx}`,
                }
            })
        } else {
            return []
        }
    }, [panelName, formContactConfig])

    const pageComponents = useMemo(() => {
        return currentPageData.map((d, idx) => {
            const displayRequiredText = shouldDisplayRequiredText(d, idx)
            return React.createElement(Form, {
                ...d,
                displayRequiredText,
                requiredText: getRequiredText(d)
            })
        })
    }, [currentPageData])
    
    const updateSubmission = useCallback((data) => {
        setSubmissionState(prev => ({
            ...prev,
            ...data.upload,
            contactDataAdded: 1 // make it so Save always execute on click even if submissionState hasn't been truly modified
        }))
    }, [activePanel, dirty])

    const updateContact = useCallback(() => {
        setIsSubmitting(true)
        authenticatedFetch(`${API_URL}/upload/facility/updateContactInfo?uploadId=${submissionState.uploadId}`, 
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify({ errorState })
        })
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setSubmissionState(prevState => ({
                    ...prevState,
                    contactInfoAdded: response.contactInfoAdded,
                }))
            })
            .catch(e => {
                if (e.name !== 'AbortError') {
                    toast({
                        level: 'error',
                        message:
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                }
            })
            .finally(() => setIsSubmitting(false))
    }, [submissionState, errorState])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    useEffect(() => {
        if (activePanel === panelName) {
            setFormMethods(formMethods)
        }
    }, [activePanel])

    useEffect(() => formMethods.reset(defaultValues), [activePanel, defaultValues])

    useEffect(() => setReactiveValues(watch({ nest: true }), formMethods.setValue), [formValues])
    
    const continueDisabled = useMemo(() => {
        return !submissionState.uploadModified
            || (errorState && Object.keys(errorState).length !== 0 && errorState.upload.length)
    }, [submissionState, errorState])

    return (
        <div
            className={`columns is-multiline is-centered ${
                (activePanel !== `Form Contact Information` && !printable) ? 'is-hidden' : ''
            }`}
        >
            {loadingConfig ? (
                <SimpleSpinner />
            ) : (
                <FormContext {...formMethods}>
                <form
                    onSubmit={handleSubmit(updateSubmission)}
                    noValidate
                    className="columns is-multiline explorerForm is-centered"
                >
                        {pageComponents}
                        <div className="column is-4 buttonWrapper">
                            <div className="saveButtonWrapper">
                                <button 
                                    type="button"
                                    disabled={continueDisabled}
                                    className={`button is-link is-medium ${isSubmitting ? 'is-loading' : ''}`}
                                    onClick={() => updateContact()}
                                >
                                    Continue
                                </button>
                            </div>
                            <div className="saveButtonWrapper">
                                <button
                                    type="submit"
                                    className={`button is-link is-medium ${isSubmitting ? 'is-loading' : ''}`}
                                >
                                    Save
                                </button>
                            </div>
                            <div className="saveButtonWrapper">
                                <button
                                    type="button"
                                    className="button is-medium"
                                    onClick={() => formMethods.reset()}
                                >
                                    {`Reset`}
                                </button>
                            </div>
                        </div>
                    </form>
                </FormContext>
            )}
        </div>
    )
}

export default withConfig(FormContactInfo)