import React, { createContext } from 'react'

import { wellPanel as panelClassConfig } from '../../../../utils/styles/panelStyles'
import Explorer from '../../../elem/list/Explorer'
import WithConfig from '../../../wrappers/withConfig'

const DataContext = createContext(null) 

export default WithConfig(({ width, explorerPage=false, config }) => {
    const { FACILITY_TITLE } = config
    return (
        <Explorer 
            formName='well' 
            name='Well' 
            apiController='well' 
            DataContext={DataContext} 
            width={width} 
            explorerPage={explorerPage}
            panelTitle={FACILITY_TITLE}
            panelClassConfig={panelClassConfig}
        />
    )
})