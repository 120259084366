import React, { useContext } from 'react'

import { DataContext } from '../../DataContext'
import Assignment from './Assignment'
import FacilityAssignmentContextProvider from './DataContext'
import PanelStyleContextProvider from '../../../../elem/panel/PanelStyleContext'

import FacilityFilter from './FacilityFilter'

const FacilityAssignment = () => {
    const {
        activePanel,
        viewOnly,
    } = useContext(DataContext)

    return (
        <div
            className={`columns is-multiline
            ${activePanel !== 'Sample Point Assignments' ? 'is-hidden' : ''}
        `}
        >
            <FacilityAssignmentContextProvider>
                <PanelStyleContextProvider panelClassConfig={{}}>
                    {!viewOnly && <FacilityFilter />}
                    <Assignment />
                </PanelStyleContextProvider>
            </FacilityAssignmentContextProvider>
        </div>
    )
}

export default FacilityAssignment
