import React from 'react'

import DetailAnalyteChart from './DetailAnalyteChart'
// import DetailWaterLevelChart from './DetailWaterLevelChart'
// import ChartTabs from './ChartTabs'
// import ChartControls from './ChartControls'
import AnalyteDropdown from './AnalyteDropdown'
import DetailMatrixDropdown from './DetailMatrixDropdown'
import TimeWindowDropdown from './TimeWindowDropdown'
import ResetDropdown from './ResetDropdown'

const DetailChart = () => {
    return (
        <div>
            {/* <ChartTabs /> */}
            {/* <ChartControls /> */}
            <ResetDropdown />
            <DetailMatrixDropdown />
            <AnalyteDropdown />
            <TimeWindowDropdown />
            <DetailAnalyteChart />
            {/* <DetailWaterLevelChart /> */}
        </div>
    )
}

export default DetailChart