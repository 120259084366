export default {
    'project': {
        project: null,
        projectAlias: null,
        projectFacility: []
    },
    'facility': {
        facility: null,
        facilityAlias: null,
        sample: [],
        result: [],
        resultBatch: [],
        labBatchQc: [],
        sampleCogccrelated: [],
        aquifer: []
    },
}
