const well = {
    PageSize: 50,
    Page: 1,
}

// const waterLevel = {
//     PageSize: 50,
//     Page: 1,
// }

const sample = {
    PageSize: 50,
    Page: 1,
}

const defaultParams = {
    well: { 
        well, 
        // waterLevel: {}, 
        sample: {} 
    },
    sample: { 
        sample, 
        // waterLeveL: {}, 
        well: {} 
    },
    // waterLevel: {sample: {}, well: {}, waterLevel },
    home: { 
        well, 
        sample, 
        // waterLevel, 
        app: { promoted: false } 
    },
    map: {},
}

export { defaultParams }
