import React from 'react'
import { DownArrow, UpArrow } from '../Arrows'

const ResetDropdownButton = ({ resetExpanded, toggleResetExpanded, inHeader=true }) => {
    return (
            <div
                className="panelButton"
                onClick={() => toggleResetExpanded(!resetExpanded)}
            >
                <span className="panelTimeOptionText">Reset</span>
                <span className="panelTimeOptionDropdown">
                    {resetExpanded ? <UpArrow className={`${inHeader ? 'is-white' : ''}`}/> : <DownArrow className={`${inHeader ? 'is-white' : ''}`}/>}
                </span>
            </div>
    )
}

export default ResetDropdownButton
