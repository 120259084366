import React, { useContext, useMemo } from 'react'

import { getSubmittedColumns } from '../../../../utils/submissions/table'
import SimpleTable from '../../../elem/table/SimpleTable'

import { TableContext } from './TableContext'
import NoDataComponent from './NoDataComponent'
import { UserContext } from '../../../wrappers/UserContext'
import { isReviewerRole } from '../../../../utils/user/permissions'

const SubmittedForms = () => {
    const { uploads, setSelectedUploads } = useContext(TableContext)
    const { roles } = useContext(UserContext)
    const isReviewer = useMemo(() => isReviewerRole(roles), [roles])

    if (!uploads || !uploads.complete.length) {
        return (
            <NoDataComponent
                message={'There are no submitted forms to display.'}
            />
        )
    }

    const { complete: data } = uploads
    const columns = useMemo(() => getSubmittedColumns(data, isReviewer), [isReviewer, data])

    return (
        <div className="container">
            <div className="container">
                <SimpleTable columns={columns} data={data} selectable={true} onSelect={setSelectedUploads} sortable={true} filterable={true}/>
            </div>
        </div>
    )
}

export default SubmittedForms