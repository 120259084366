import React, { useContext, } from 'react'

import { getInProgressColumns } from '../../../../utils/submissions/table'
import SimpleTable from '../../../elem/table/SimpleTable'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import toast from '../../../elem/Toast'

import { TableContext } from './TableContext'
import NoDataComponent from './NoDataComponent'
import withConfig from '../../../wrappers/withConfig'
import ConfirmDelete from './ConfirmDelete'

const FormsInProgress = ({ config }) => {
    const { uploads, setUploads, setSelectedUploads, setConfirmDeleteModalData } = useContext(TableContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { API_URL } = config

    if (!uploads || !uploads.incomplete.length) {
        return (
            <div className="container">
                <NoDataComponent
                    message={'There are no forms in progress to display.'}
                />
            </div>
        )
    }
    const { incomplete: data } = uploads

    const deleteFunction = async uploadId => {
        await authenticatedFetch(
            `${API_URL}/upload/delete?uploadId=${uploadId}`,
            {
                method: 'DELETE',
            }
        )
            .then(() =>
                setUploads(previousUploads => ({
                    ...previousUploads,
                    incomplete: previousUploads.incomplete.filter(
                        x => x['Form ID'] !== uploadId
                    ),
                }))
            )
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Submission Delete: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(
                setConfirmDeleteModalData({ display: false, uploadId: null, formType: null }) //stop displaying the confirm delete modal
            ) 
    }
    const columns = getInProgressColumns(data, setConfirmDeleteModalData)

    return (
        <div className="container">
            <div className="container">
                <ConfirmDelete deleteFunction={deleteFunction}/> 
                <SimpleTable columns={columns} data={data} selectable={true} onSelect={setSelectedUploads} sortable={true} filterable={true} />
            </div>
        </div>
    )
}

export default withConfig(FormsInProgress)
