const getAxisYDomain = (from, to, filterFunction, offset, initialData, xAxisKey) => {
    const refData = initialData.filter(x => x[xAxisKey] < to && x[xAxisKey] > from - 1)
    const keys = Object.keys(refData[0])
    const values = keys.filter(filterFunction).map(x => refData[0][x])
    const initialValue = Math.min(...values)
    let [bottom, top] = [initialValue, initialValue]
    refData.forEach(d => {
        const minValue = Math.min(...(Object.keys(d).filter(filterFunction).map(x => d[x]))) 
        const maxValue = Math.max(...(Object.keys(d).filter(filterFunction).map(x => d[x]))) 
        if (maxValue > top) top = maxValue
        if (minValue < bottom) bottom = minValue
    })

    let returnBottom = (bottom | 0) - offset
    if (returnBottom < 0) {
       returnBottom = 0 
    }
    let returnTop = (top | 0 ) + offset
    if (returnTop <= 0) {
        returnTop = top
    }
    return [returnBottom, top]
}

export { getAxisYDomain }