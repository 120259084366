import React, { createContext, useCallback, useState, useEffect, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import urls from '../../../utils/constants/urls'
import { ParameterContext } from '../../wrappers/ParameterContext'

const AppStateContext = createContext(null)

const AppStateContextProvider = ({ children }) => {
    const { params, setParams } = useContext(ParameterContext)
    const { pathname } = useLocation()
    const isHomePage = useMemo(() => pathname === urls.home, [pathname])

    
    const [promotedRecords, setPromotedRecords] = useState({
        'Sample': true, 
        'Well': true, 
        // 'WaterLevel': false
    })

    const [detailState, setDetailState] = useState({
        well: {
            visible: false,
            facilityID: null,
        }
    })

    const [filterState, setFilterState] = useState({
        well: !isHomePage,
        sample: !isHomePage,
        // waterLevel: false
    })

    const [mapState, setMapState] = useState({
        selectedFeatures: [],
        allFeatures: [],
        filterFeatures: [],
        mapSelectionIds: "",
        map: null,
    })

    const updateDetailState = (name, newState) => {
        const newDetailState = {
            [name]: {
                ...detailState[name],
                ...newState,
            },
        }
        setDetailState({
            ...detailState,
            ...newDetailState,
        })
    }

    const updateFilterState = (name, newState) => {
        setFilterState({
            ...filterState,
            [name]: newState
        })
    }

    const syncFilterState = newState => {
        const newFilters = Object.keys(filterState).map(filter => ({[filter]: newState}))
        setFilterState(newFilters)
    }

    const togglePromotedRecords = useCallback((promoted, field) => {
        const newParams = {
            ...params.app,
            [`${field}Promoted`]: promoted
        }
        setPromotedRecords({...promotedRecords, [field]: promoted})
        setParams(newParams, 'app')
    }, [params])

    useEffect(() => {
        if (promotedRecords.Well || promotedRecords.Sample) {
            const newParams = {
                ...params.app,
                [`WellPromoted`]: true,
                [`SamplePromoted`]: true,
            }           
            setParams(newParams, 'app')   
        }
    }, [])

    useEffect(() => {
      setFilterState({
        well: !isHomePage,
        sample: !isHomePage,
      })
    }, [isHomePage])
    
    return (
        <AppStateContext.Provider
            value={{
                detailState,
                updateDetailState,
                mapState,
                setMapState,
                filterState,
                updateFilterState,
                syncFilterState,
                promotedRecords, 
                togglePromotedRecords
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

export { AppStateContext }
export default AppStateContextProvider
