import React, { useEffect, useState, createContext, useContext, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import { DataContext } from '../DataContext'
import { UserContext } from '../../../wrappers/UserContext'
import actions from '../../../../utils/submissions/actions'

const ExistingDataContext = createContext(null)

const ExistingDataContextProvider = ({ children, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setActiveAgency, initializeState, submissionState, setPrimaryKey, formType, setErrorState, setIsSubmitting, isReview } = useContext(DataContext)
    const { user } = useContext(UserContext)
    const { uploadId } = useParams()
    const history = useHistory()
    const [uploadData, setUploadData] = useState({})
    const [uploadChanges, setUploadChangeData] = useState({})
    const [reviewText, setReviewText] = useState(null)
    const [uploadChangesAbortController, setUploadChangesAbortController] = useState(new AbortController())
    const { API_URL } = config

    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId)) && actions.isValidFormType(formType)) {
            actions.getExistingSubmission(authenticatedFetch, API_URL, uploadId, formType, setUploadData)
        }
    }, [uploadId, formType])

    useEffect(() => {
        if (uploadData && Object.keys(uploadData).length) {
            setActiveAgency(uploadData.agencyCode)
            initializeState(uploadData)
            setErrorState({})
            if (uploadData && uploadData[formType.PrimaryAccessor]) {
                setPrimaryKey(uploadData[formType.PrimaryAccessor][formType.UploadKeyField])
            } else {
                setPrimaryKey(null)
            }
        }
    }, [uploadData, formType])
    
    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId)) && actions.isValidFormType(formType)) {
            uploadChangesAbortController.abort()
            const newAbortController = new AbortController()
            setUploadChangesAbortController(newAbortController)
            actions.getUploadChanges(authenticatedFetch, API_URL, uploadId, formType, setUploadChangeData, newAbortController)
        }
    }, [uploadId, formType, setUploadChangesAbortController])

    useEffect(() => {
        if (!(uploadId === null || isNaN(uploadId)) && actions.isValidFormType(formType) && isReview) {
            actions.getReviewText(authenticatedFetch, API_URL, uploadId, setReviewText)
        }
    }, [submissionState, uploadId, formType, isReview])

    const rejectUpload = useCallback(
        comments => {
            if (!(uploadId === null || isNaN(uploadId)) && user && user.profile) {
                setIsSubmitting(true)
                actions.reject(authenticatedFetch, API_URL, history, uploadId, comments, user, () => setIsSubmitting(false))
            }
        },
        [submissionState, uploadId, user]
    )

    const approveUpload = useCallback(
        comments => {
            if (!(uploadId === null || isNaN(uploadId))) {
                setIsSubmitting(true)
                actions.approve(authenticatedFetch, API_URL, history, uploadId, comments, user, () => setIsSubmitting(false))
            }
        },
        [submissionState, uploadId, user]
    )

    return (
        <ExistingDataContext.Provider
            value={{
                uploadData,
                uploadChanges,
                rejectUpload,
                approveUpload,
                reviewText,
            }}
        >
            {children}
        </ExistingDataContext.Provider>
    )
}

export { ExistingDataContext }
export default withConfig(ExistingDataContextProvider)