import React from 'react'
import { getConfigRefCodeValue } from '../../../../../utils/submissions/values'

const FacilityInformation = ({
    data,
    dataChanged,
    uploadConfig,
}) => {
    const facility = data && data.facility

    if (
        (dataChanged) ||
        !(
            facility &&
            Object.keys(facility).some(
                x => data.facility[x] != null
            )
        )
    ) {
        return null
    }

    return facility && (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Sample Point Information:{' '}
            </div>
            <div className='columns'>
                <div className='column is-6'>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Sample Point Name: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.facilityName ? facility.facilityName : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">API Number: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.apiNumber ? facility.apiNumber : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Sample Point Type: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.facilityType 
                                            ? getConfigRefCodeValue('FacilityType', facility.facilityType, uploadConfig)
                                            : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column is-6'>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">County: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.countyId
                                                ? getConfigRefCodeValue('CountyKey', facility.countyId, uploadConfig)
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Sec | Twp | Range | Meridian: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility
                                                ? facility.section + ' | ' + facility.township + facility.townshipDir + ' | ' 
                                                    + facility.range + facility.rangeDir + ' | '
                                                    + `${facility.meridian ? getConfigRefCodeValue('Meridian', facility.meridian, uploadConfig) : facility.meridian}`
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Latitude (NAD83) | Longitude (NAD83): </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.latitude83 && facility.longitude83
                                                ? facility.latitude83 + ' | ' + facility.longitude83
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default FacilityInformation