import React, { useContext } from 'react'

import Filter from './Filter'
import List from './List'
import Panel from '../panel/Panel'
import { AppStateContext } from '../../features/explorer/AppStateContext'
import DataContextProvider from './DataContextProvider'
import Header from './Header'

const Explorer = ({
    name,
    formName,
    DataContext,
    apiController,
    width,
    explorerPage,
    panelTitle,
    panelClassConfig
}) => {
    const {
        filterState,
        updateFilterState,
        syncFilterState,
        promotedRecords,
        togglePromotedRecords,
    } = useContext(AppStateContext)
   
    const filterExpanded = filterState[formName]

    const toggleFilter = updateFilterState.bind(this, formName)
    return (
        <Panel panelClassConfig={panelClassConfig(width)}>
            <div className={`${explorerPage ? 'explorerPage' : ''} explorerWrapper`}>
                <DataContextProvider
                    name={name}
                    formName={formName}
                    apiController={apiController}
                    DataContext={DataContext}
                >
                    <Header
                        filterExpanded={filterExpanded}
                        toggleFilter={toggleFilter}
                        promotedRecords={promotedRecords[name]}
                        togglePromotedRecords={togglePromotedRecords}
                        extraClass={`${formName}Header`}
                        name={name}
                        explorerPage={explorerPage}
                        formName={formName}
                        panelTitle={panelTitle}
                    />
                    <div className={`${explorerPage ? 'columns explorerColumns' : ''}`}>
                        <List
                            promotedRecords={promotedRecords}
                            filterExpanded={filterExpanded}
                            toggleFilter={toggleFilter}
                            explorerPage={explorerPage}
                            DataContext={DataContext}
                        />
                        <Filter
                            width={width}
                            explorerPage={explorerPage}
                            filterExpanded={filterExpanded}
                            syncFilterState={syncFilterState}
                            DataContext={DataContext}
                        />
                    </div>
                </DataContextProvider>
            </div>
        </Panel>
    )
}

export default Explorer
