import React, { useState, } from 'react'
import { getConfigRefCodeValue } from '../../../../../utils/submissions/values'
import dayjs from 'dayjs'

const ProjectInformation = ({
    data,
    dataChanged,
    uploadConfig,
}) => {
    // const [files, setFiles] = useState([])
    const project = data && data.project

    if (
        (dataChanged) ||
        !(project && Object.keys(project).some(x => project[x] != null))
    ) {
        return null
    }

    return project && (
        <form className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Project Information:{' '}
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Name: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    project && project.projectName
                                        ? project.projectName
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Project Type: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    project && project.projectType
                                        ? getConfigRefCodeValue('ProjectType', project.projectType , uploadConfig) 
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Last Modified Date: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    project && project.modifyDate
                                        ? dayjs(project.modifyDate).format('MM/DD/YYYY').toString()
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="field is-horizontal">
                <div className="field-label">
                    <label className="label">Last Modified User: </label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input
                                disabled
                                className="input is-small"
                                value={
                                    project && project.modifyUser
                                        ? project.modifyUser
                                        : ''
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default ProjectInformation
