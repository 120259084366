import React, { useContext } from 'react'
import { IconContext } from 'react-icons'
import { FaCheck, FaSpinner } from 'react-icons/fa'

import { EDDDataContext } from './EDDDataContext'

const ValidationResults = ({ results, level }) => {
    if (results.length) {
        return (
            <div className="eddResultsWrapper">
                {/* ${level === 'warning' ? 'has-text-warning' : 'has-text-danger'} */}
                <p className={`eddResultsTitle is-size-4`}>
                    {level === 'Warning' ? 'Warnings' : 'Errors'}: [
                    {`${results.length}`}]
                </p>
                <div className="content eddResultsBody">
                    <ul>
                        {results.map((result, idx) => (
                            <li key={`validation-result-${idx}`}>
                                <p
                                    className={` ${
                                        level === 'Warning' ? 'has-text-warning-dark' : 'has-text-danger-dark'
                                    } has-text-left`}
                                >
                                    {result}
                                </p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const TableValidationResults = ({ results, level }) => {
    const r = Object.keys(results).reduce((acc, curr, i) => {
        const associatedResults = results[curr]

        return [
            ...acc,
            ...associatedResults.map((result, j) => (
                <li key={`validation-result-${i}-${j}`}>
                    <p
                        className={` ${
                            level === 'Warning' ? 'has-text-warning-dark' : 'has-text-danger-dark'
                        } has-text-left`}
                    >
                        {result.errorString}
                    </p>
                </li>
            )),
        ]
    }, [])
    if (r.length) {
        return (
            <div className="eddResultsWrapper">
                <p className={`eddResultsTitle is-size-4`}>
                    {level === 'Warning' ? 'Warnings' : 'Errors'}: [{`${r.length}`}]
                </p>
                <div className="content eddResultsBody">
                    <ul>{r}</ul>
                </div>
            </div>
        )
    } else {
        return null
    }
}

const PageErrorText = ({ string }) => {
    if (string) {
        return (
            <div className="eddResultsWrapper">
                <div className="content eddResultsBody">
                    <p className="has-text-danger">
                        {string}
                    </p>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default ({ isValid, isTable }) => {
    const { validationResults, loading } = useContext(EDDDataContext)
    return (
        <div className="validationWrapper">
            {loading && (
                <div className="validationIndicatorWrapper">
                    <span className="icon">
                        <IconContext.Provider value={{ className: 'fa-pulse has-text-link' }}>
                            <FaSpinner />
                        </IconContext.Provider>
                    </span>
                    <span>
                        {isValid ? 'Submitting' : 'Validating'} EDD. Please
                        wait...
                    </span>
                </div>
            )}
            {isValid && !loading && (
                <div className="validationIndicatorWrapper">
                    <span className="icon">
                        <IconContext.Provider value={{ className: 'has-text-success' }}>
                            <FaCheck />
                        </IconContext.Provider>
                    </span>
                    <span>
                        EDD passed validation!
                    </span>
                </div>
        )}
            {validationResults ? (
                <>
                    <PageErrorText string={validationResults.pageErrorText} />
                    {isTable ? (
                        <TableValidationResults
                            results={validationResults.errors}
                            level={'Error'}
                        />
                    ) : (
                        <ValidationResults
                            results={validationResults.errors}
                            level={'Error'}
                        />
                    )}
                    {isTable ? (
                        <TableValidationResults
                            results={validationResults.warnings}
                            level={'Warning'}
                        />
                    ) : (
                        <ValidationResults
                            results={validationResults.warnings}
                            level={'Warning'}
                        />
                    )}
                </>
            ) : null}
        </div>
    )
}
