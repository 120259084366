import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { DataContext } from '../../DataContext'
import withConfig from '../../../../wrappers/withConfig'
import ToggleComponent from '../ToggleComponent'
import IdentifySamplePointForm from './IdentifySamplePointForm'
import AssociatedFacilityComponent from './AssociatedFacilityComponent'
import NewSearchComponent from './NewSearchComponent'
import defaultUploadObjects from '../../../../../utils/submissions/defaultUploadObjects'

const SaveAndContinueButton = withConfig(({
    config,
    facilityData,
    firstFacility,
    aliasStateData,
    continueDisabled,
}) => {
    const {
        setSubmissionState,
        submissionState,
        isSubmitting,
        setTableData,
        formType,
    } = useContext(DataContext)
    const [error, setError] = useState(null)

    const emptyUploadState = defaultUploadObjects[formType.APILink]

    const mergeData = useCallback(() => {
        const facility = {
            facility: {
                ...facilityData && facilityData.facility,
                associatedFacilityId: firstFacility && firstFacility.facility && firstFacility.facility.facilityId
            },
        }
        setSubmissionState(prevSubmissionState => ({
            ...prevSubmissionState,
            ...emptyUploadState, 
            new: Math.random() * 1000,
            ...facilityData,
            ...aliasStateData,
            ...facility,
        }))
        setTableData({})
    }, [facilityData, aliasStateData, firstFacility])

    useEffect(() => {
        if (continueDisabled) {
            setError(null)
        }
    }, [continueDisabled])

    return (
        <>
            {error ? 
                <p className="is-full has-text-centered has-text-danger">
                    {error}
                </p> : null 
            }
            <div className="buttonWrapper">
                <button
                    type="button"
                    onClick={() => mergeData()}
                    disabled={continueDisabled}
                    className={`button is-medium is-link ${
                        isSubmitting ? 'is-loading' : ''
                    }`}
                >
                    {`Continue`}
                </button>
            </div>
                
        </>
    )
})

const SamplePointSearchForm = ({ formType }) => {
    const [isNew, setIsNew] = useState(true)
    const [isAssociatedOG, setIsAssociatedOG] = useState(true)
    const { 
        activePanel,
        setPrimaryKey,
        uploadConfig,
    } = useContext(DataContext)
    const [firstFacility, setFirstFacility] = useState(null)
    const [aliasStateData, setAliasStateData] = useState(null)
    const [facilityData, setFacilityData] = useState(null)
    const [facilityChanged, setFacilityChanged] = useState(true)
    const [firstFacilityChanged, setFirstFacilityChanged] = useState(false)
    const [aliasChanged, setAliasChanged] = useState(false)

    useEffect(() => {
        setFacilityData(null)
    }, [firstFacilityChanged])

    useEffect(() => {
        setFacilityData(null)
        setFirstFacility(null)
    }, [isAssociatedOG])

    const continueDisabled = useMemo(() => {
        if (isNew && !aliasChanged && !firstFacilityChanged) {
            if (
                (aliasStateData && aliasStateData.facilityAlias && !aliasChanged)
                || (isAssociatedOG && firstFacility && firstFacility.facility && firstFacility.facility.facilityId && !firstFacilityChanged)
                || (!isAssociatedOG && !(aliasStateData && aliasStateData.facilityAlias))
            ) {
                return false
            }
        } else {
            if (facilityData && facilityData.facility && !facilityChanged) {
                return false
            }
        }
        return true
    }, [facilityData, firstFacility, isNew, firstFacilityChanged, aliasStateData, aliasChanged, facilityChanged, isAssociatedOG])

    return (
        <div
            className={`columns is-multiline ${
                activePanel !== `Sample Point Search` ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <ToggleComponent
                    onDropDownChange={(newItem, isAssociated) => {
                        setFirstFacility(null)
                        setPrimaryKey(null)
                        if (isAssociated != null) {
                            setIsAssociatedOG(isAssociated)                            
                        }
                        if (newItem !== null) {
                            setIsNew(newItem)                            
                        }
                    }}
                    formType={formType}
                />
            </div>
            <div className="column is-12">
                <AssociatedFacilityComponent
                    firstFacility={firstFacility}
                    setFirstFacility={setFirstFacility}
                    setFirstFacilityChanged={setFirstFacilityChanged}
                    firstFacilityChanged={firstFacilityChanged}
                    isAssociatedOG={isAssociatedOG}
                />
            </div>
            {isNew ? (
                <div className="column is-12">
                    <NewSearchComponent
                        setAliasStateData={setAliasStateData}
                        aliasChanged={aliasChanged}
                        setAliasChanged={setAliasChanged}
                    />
                </div>
            ) : (<>
                {(firstFacility && firstFacility.facility && !firstFacilityChanged) || !isAssociatedOG ? (
                    <div className="column is-12">
                        <IdentifySamplePointForm
                            facilityData={facilityData}
                            setFacilityData={setFacilityData}
                            facilityChanged={facilityChanged}
                            setFacilityChanged={setFacilityChanged}
                            uploadConfig={uploadConfig}
                            isAssociatedOG={isAssociatedOG}
                        />
                    </div>
                ) : null}
            </>)}
            <div className="column is-12">
                <SaveAndContinueButton
                    facilityData={facilityData}
                    firstFacility={firstFacility}
                    aliasStateData={aliasStateData}
                    continueDisabled={continueDisabled}
                />
            </div>
        </div>
    )
}

export default SamplePointSearchForm
