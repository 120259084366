import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useMemo,
} from 'react'
import { useForm } from 'react-hook-form'

import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { DataContext } from '../DataContext'
import withConfig from '../../../wrappers/withConfig'
import { aliasHelperText } from '../../../../utils/submissions/helperText'
import { ExistingDataContext } from './ExistingSubmissionDataContext'
import { isChanged } from '../../../../utils/submissions/values'

const AliasForm = ({ formType, config }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const {
        activePanel,
        activeAgency,
        submissionState,
        viewOnly,
        setFormMethods,
        setFormDirty,
        setSubmissionState,
        printableView: printable,
    } = useContext(DataContext)
    const { uploadChanges } = useContext(ExistingDataContext)

    const aliasAccessor = `${formType.PrimaryAccessor}Alias`
    const [alias, setAlias] = useState()
    const [aliasData, setAliasData] = useState(submissionState[aliasAccessor])
    const [loading, setLoading] = useState(false)
    const defaultValues = useMemo(() => aliasData ? {'alias': aliasData.alias} : null, [aliasData])
    const formConfig = defaultValues ? {mode: 'onChange', defaultValues} : {mode: 'onChange'}
    const formMethods = useForm(formConfig)
    const { handleSubmit, formState, watch, } = formMethods
    const { dirty, } = formState
    const { API_URL } = config
    const [duplicateAliasError, setDuplicateAliasError] = useState(null)
    const formName = formType.FormType

    useEffect(() => {
        if (watch('alias' !== alias)) {
            setDuplicateAliasError(null)
        }
    }, [watch('alias'), alias])

    const changed = useMemo(() => {
        const c = isChanged(
            uploadChanges,
            aliasAccessor,
            'alias',
            undefined,
            submissionState
        )
        return c
    }, [uploadChanges, aliasAccessor, submissionState])

    useEffect(() => {
        if (activePanel === `${formName} Alias Information`) {
            setFormMethods(formMethods)
        }
    }, [defaultValues])

    useEffect(() => {
        // if (activePanel === `${formName} Alias Information`) {
            formMethods.reset(defaultValues)
        // }
    }, [defaultValues, activePanel, formType])

    const updateAlias = useCallback(() => {
        setLoading(true)
        var currentUploadId = submissionState && submissionState.uploadId
        // make sure the alias is allowed and submit
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/CheckDuplicateAlias?Alias=${alias}&agencyCode=${activeAgency}&currentUploadId=${currentUploadId}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                setAliasData(prevAliasData => ({
                    ...prevAliasData,
                    alias: alias,
                })) 
                setDuplicateAliasError(null)
            })
            .catch(e => {
                setDuplicateAliasError(e.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [alias])

    useEffect(() => {
        setSubmissionState(prevSubmissionSate => {
            return {
            ...prevSubmissionSate,
            [aliasAccessor]: aliasData,
        }})
        setAlias(aliasData ? aliasData.alias : null)
    }, [aliasData])

    useEffect(() => {
        setAliasData(
            submissionState[aliasAccessor] ? submissionState[aliasAccessor] : null
        )
    }, [submissionState])

    useEffect(() => {
        setFormDirty(dirty)
    }, [dirty])

    const belowAliasText = formType.APILink === 'project' 
        ? `Project Alias is the name or number used by the submitter to identify this project.` 
        : aliasHelperText
            .replace('%IDENTIFIER%', 'sample point')
            .replaceAll('%NAME%', formName)
            .replaceAll('%SYSTEM%', formName === 'Project' ? 'submitter’s numbering' : 'WaterStar')

    return (
        <div
            className={`columns is-multiline is-centered ${
                (activePanel !== `${formName} Alias Information` && !printable) ? 'is-hidden' : ''
            }`}
        >
            <div className="column is-12">
                <form
                    className="form"
                    onSubmit={handleSubmit(() => updateAlias())}
                >
                    <div className="is-size-4 has-text-centered matchingFacilityText">
                        <span className="has-text-weight-semibold">
                            {`${formName} Alias Information:`}
                        </span>
                    </div>
                    <div className="columns is-vcentered is-centered field is-horizontal ">
                        <div className="column is-1 field-label is-align-items-center">
                            <label className="label">Alias:</label>
                        </div>
                        <div className="column is-10 field-body">
                            <div className="field">
                                <div className="control">
                                    <input
                                        disabled={viewOnly || printable}
                                        className={`input is-fullwidth 
                                        ${
                                            changed && !printable
                                                ? 'is-changed'
                                                : ''
                                        }
                                        ${
                                            printable ? 'is-printable' : ''
                                        }`}
                                        name={'alias'}
                                        ref={formMethods.register}
                                        // value={alias}
                                        onChange={e => {
                                            setAlias(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="m-0 has-text-centered help has-text-grey">
                        {belowAliasText}
                    </span>
                    {!viewOnly && !printable && (
                        <div className="columns is-centered is-multiline">
                            <div className="column is-12 has-text-centered">
                                {duplicateAliasError ? (
                                    <div className="has-text-danger">
                                        {duplicateAliasError}
                                    </div>
                                ) : null}
                            </div>
                            <div className="column is-4 buttonWrapper">
                                <div className="saveButtonWrapper">
                                    <button
                                        className={`button is-link is-medium ${
                                            loading ? 'is-loading' : ''
                                        }`}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                                <div className="saveButtonWrapper">
                                    <button
                                        type="button"
                                        className="button is-medium"
                                        onClick={() => {
                                            return formMethods.reset()
                                        }}
                                    >
                                        {`Reset`}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}

export default withConfig(AliasForm)