import React, { useContext } from 'react'
import { SimpleSpinner } from '../../../../elem/Spinner'

import AssignedFacilities from './AssignedFacilities'
import AssignmentActions from './AssignmentActions'
import { FacilityAssignmentContext } from './DataContext'
import PossibleFacilities from './PossibleFacilities'
import { DataContext } from '../../DataContext'

const Assignment = () => {
    const { viewOnly } = useContext(DataContext)
    const { loadingConfig } = useContext(FacilityAssignmentContext)
    return (
        <div className="column is-12">
            {loadingConfig ? (
                <SimpleSpinner extraClass={'inlineSpinner'} />
            ) : (
                <div className="columns is-multiline">
                    {!viewOnly && (
                        <div className="column is-12">
                            <div className="is-size-4 has-text-centered matchingFacilityText">
                                <span className="has-text-weight-semibold">
                                    {`Assign Sample Points to a Project`}
                                </span>
                            </div>
                        </div>
                    )}
                    <div className="column is-12">
                        <div className="assignmentWrapper">
                            {!viewOnly && <PossibleFacilities />}
                            {!viewOnly && <AssignmentActions />}
                            <AssignedFacilities />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Assignment
