import React, { useContext } from 'react'

import { DataContext } from './DataContext'
import { PanelStyleContext } from '../../../../elem/panel/PanelStyleContext'
import MatrixSelector from '../../../../elem/chart/MatrixSelector'

export default () => {
    const {
        selectedMatrix,
        setSelectedMatrix,
        displayMatrixWindowDropdown,
        analyteList,
        matrixList
    } = useContext(DataContext)
    const { colorClass } = useContext(PanelStyleContext)

    if (!analyteList || !analyteList.length) {
        return null
    }

    return (
        <MatrixSelector
            selectedMatrix={selectedMatrix}
            setSelectedMatrix={setSelectedMatrix}
            analyteList={analyteList}
            displayMatrixWindowDropdown={displayMatrixWindowDropdown}
            colorClass={colorClass}
            matrixList={matrixList}
        />
    )
}
