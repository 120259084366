import React, { useContext } from 'react'
import { getConfigRefCodeValue } from '../../../../../utils/submissions/values'
import { DataContext } from '../../DataContext'

const FirstFacilityInformation = ({
    firstFacility,
    facilityChanged,
}) => {
    const { 
        viewOnly,
        uploadConfig,
    } = useContext(DataContext)

    const facility = firstFacility && firstFacility.facility
    if (
        (facilityChanged && (!viewOnly)) ||
        !(
            facility &&
            Object.keys(facility).some(
                x => firstFacility.facility[x] != null
            )
        )
    ) {
        return null
    }

    return facility ? (
        <div className="form is-centered">
            <div className="is-size-4 has-text-weight-semibold has-text-centered matchingFacilityText">
                Associated Facility Information:{' '}
            </div>
            <div className='columns'>
                <div className='column is-6'>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Facility Name: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility ? facility.facilityName : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">API Number: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.apiNumber
                                                ? facility.apiNumber
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Operator: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.operatorName
                                                ? facility.operatorName
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Facility Type: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.facilityType 
                                            ? getConfigRefCodeValue('FacilityType', facility.facilityType, uploadConfig)
                                            : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column is-6'>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Facility Status: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.facilityStatus
                                                ? facility.facilityStatus
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">County: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility && facility.countyId
                                                ? getConfigRefCodeValue('CountyKey', facility.countyId, uploadConfig)
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Sec | Twp | Range | Meridian: </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility
                                                ? `${facility.section} | ${facility.township}${facility.townshipDir} | ${facility.range}${facility.rangeDir} | ` 
                                                    + `${facility.meridian ? getConfigRefCodeValue('Meridian', facility.meridian, uploadConfig).trim() : facility.meridian}` 
                                            : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field is-horizontal">
                        <div className="field-label">
                            <label className="label">Latitude (NAD83) | Longitude (NAD83): </label>
                        </div>
                        <div className="field-body">
                            <div className="field">
                                <div className="control is-expanded">
                                    <input
                                        disabled
                                        className="input is-small"
                                        value={
                                            facility
                                                ? facility.latitude83 + ' | ' + facility.longitude83
                                                : ''
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default FirstFacilityInformation