import React, {
    useState,
    useContext,
    useMemo,
    useCallback,
    useEffect,
} from 'react'

import { APIRequestContext } from '../../../../wrappers/APIRequestContext'
import { DataContext } from '../../DataContext'
import withConfig from '../../../../wrappers/withConfig'
import { FaCheck } from 'react-icons/fa'

const NewSearchComponent = withConfig(({ config, setAliasStateData, aliasChanged, setAliasChanged, setFieldsDirty }) => {
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { activeAgency, formType } = useContext(DataContext)
    const [alias, setAlias] = useState(null)
    const searchDisabled = useMemo(() => !alias, [alias])
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
    const { API_URL } = config

    useEffect(() => {
        if (alias === '' || aliasChanged) {
            setAliasStateData(null)
            setSuccess(null)
            setError(null)
        }
    }, [aliasChanged, alias])

    const search = useCallback(() => {
        setLoading(true)
        authenticatedFetch(
            `${API_URL}/upload/${formType.APILink}/CheckDuplicateAlias?Alias=${alias}&agencyCode=${activeAgency}`
        )
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                setAliasStateData({
                    facilityAlias: {
                        alias: alias,
                        agencyCode: activeAgency
                    }
                })
                setSuccess(`Alias validated`)
                setError(null)
                setAliasChanged(false)
            })
            .catch(e => {
                setSuccess(null)
                setError(e.message)
            })
            .finally(() => setLoading(false))
    }, [alias, activeAgency, formType])

    return (<>
        <div className="field is-horizontal is-align-items-center is-full-width">
            <div className="field-label">
                <label id={`alias-label`} className="label">Sample Point Alias:</label>
            </div>
            <div className="field-body">
                <div className="field is-grouped">
                    <div className="control is-expanded">
                        <input
                            className={`input is-fullwidth formInput`}
                            name={'Alias'}
                            aria-labelledby={`alias-label`}
                            onChange={e => {
                                const v = e.target.value
                                setAliasChanged(v === '' ? false : true)
                                setAlias(v)
                            }}
                        />
                    </div>
                    <div className="control">
                        <button 
                            type="button"
                            disabled={searchDisabled}
                            className={`button is-primary mr-3 ${loading ? 'is-loading' : ''}`}
                            onClick={() => search()}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="column is-12 has-text-centered">
            {error ? <div className="has-text-danger">{error}</div> : null}
            {success ? <div className="has-text-success">{success} <FaCheck /></div> : null}
        </ div>
    </>)
})

export default NewSearchComponent