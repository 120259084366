import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import EditableTable from '../../../elem/table/EditableTable'
import { parseUploadColumns } from '../../../../utils/table/parseColumns'
import { DataContext } from '../DataContext'
import SectionWrapper from './SectionWrapper'
import GeneralErrors from './GeneralErrors'
import deepClone from 'lodash.clonedeep'
import PageErrorText from './PageErrorText'
import { useFormContext } from 'react-hook-form'
import { decapitalizeText } from '../../../../utils/stringUtils'
import { getValueFromDataRow } from '../../../../utils/submissions/values'
import ConfirmDeleteRecord from './ConfirmDeleteRecord'
import { UserContext } from '../../../wrappers/UserContext'


const getInputNameFromConfig = (config, fieldName) => {
    if (!config) {
        return fieldName
    }
    const name = decapitalizeText(config.ColumnName)
    switch (config.ControlType) {
        case 'TextBox':
            return name
        case 'Select':
            return `${name}Select`
        case 'MultiSelect':
            return `${name}Select`
        case 'DateDayPicker':
            return `${name}DateSelect`
        case 'CheckBox':
            return `${name}CheckBox`
        case 'DatePicker':
            return `${name}DateSelect`
        case 'Creatable':
            return `${name}Select`
        default:
            return name
    }
}

const removeAndShiftRemaining = (data, rowIdx) => {
    // keep the first elements of the array the same
    const keep = data.slice(0, rowIdx)
    // shift the elements after the deleted row into the other places
    const remaining = data.slice(rowIdx)
    remaining.shift()
    const splicedData = [...keep, ...remaining]
    return splicedData
}

const deleteFunction = (
    setData,
    formMethods,
    accessor,
    setFormDirty,
    config,
    removeAndShiftErrorState,
    tableProps,
) => {
    const { cell, data } = tableProps
    const rowIdx = cell.row.index
    // unregister the last row and set values in react-hook-form to new values
    const formValues = formMethods.getValues({ nest: true })
    const associatedValues = formValues[accessor]
    const lastVisibleIndex = associatedValues.length - 1
    associatedValues.map((entry, idx) => {
        if (idx >= rowIdx && idx < lastVisibleIndex) {
            const newValue = associatedValues[idx + 1]
            const fieldToUpdate = `${accessor}[${idx}]`
            Object.keys(entry).map(key => {
                const value = newValue[key]
                // const value = get(newValue, key)
                return formMethods.setValue(
                    `${fieldToUpdate}.${key}`,
                    value ? value : null
                )
            })
        }
        return null
    })

    const revIdx = associatedValues.slice().reverse().findIndex(x => !!x)
    const lastRowIdx = lastVisibleIndex - revIdx
    const lastRow = formValues[accessor][lastRowIdx]
    if (lastRowIdx === data.length - 1) {
        // unregister the fields associated with the last row
        Object.keys(lastRow).map(key => {
            const fieldName = `${accessor}[${lastRowIdx}].${key}`
            formMethods.setValue(fieldName, null)
            formMethods.unregister(`${accessor}[${lastRowIdx}].${key}`)
            return null
        })
    } else {
        const newRow = data[lastRowIdx + 1]
        Object.keys(lastRow).map(key => {
            const fieldName = `${accessor}[${lastRowIdx}].${key}`
            const newValue = getValueFromDataRow(key, newRow, config)
            formMethods.setValue(fieldName, newValue)
            return null
        })
    }

    // if this is the last row, register a flag to denote this for data update
    if (lastRowIdx === 0) {
        formMethods.register(`${accessor}`)
        formMethods.setValue(`${accessor}`, 'reset')
    } else if (associatedValues.filter(x => !!x).length - 1 === 0) {
        formMethods.register(`${accessor}`)
        formMethods.setValue(`${accessor}`, 'pageEmpty')
    }
    removeAndShiftErrorState(accessor, rowIdx) //take the API errors and delete them along side the deleted column
    // update the data associated with the table
    setData(existingData => {
        const newData = removeAndShiftRemaining(existingData, rowIdx)
        return newData
    })
    setFormDirty(true)
}

const getDataFromSubmissionState = (submissionState, accessor) =>
    submissionState && submissionState[accessor]
        ? submissionState[accessor]
        : []

export default ({ config, accessor, title, subtitle, displayRequiredText, requiredText }) => {
    const {
        submissionState,
        formMethods,
        viewOnly,
        setTableData,
        setFormDirty,
        updateControlledPageSize,
        pageErrorText,
        beforePageChange,
        printableView: printable,
        uploadConfig,
        setDisplayDeleteModal,
        errorState,
        setErrorState,
        isDisabledBasedOnMigration,
        isAuxilliaryTable
    } = useContext(DataContext)

    const { roles } = useContext(UserContext)

    const { setValue } = useFormContext()

    const [data, setData] = useState(
        getDataFromSubmissionState(submissionState, accessor)
    )

    const enableRowCreation = useMemo(() => !viewOnly && !config.some(x => x.DisableRowCreation) && !isDisabledBasedOnMigration && !isAuxilliaryTable, [config, viewOnly, submissionState])

    const removeAndShiftErrorState = useCallback((deletedRecordaccessor, rowIdx) => {
        if (!errorState || Object.keys(errorState).length === 0) {
            return null
        }
        const dummyErrorState = errorState
        const deletedRowName = `${deletedRecordaccessor}[${rowIdx}]`
        const deletedRowPosition = deletedRowName.split(deletedRecordaccessor)[1][1]
        delete dummyErrorState[deletedRowName]
        let elementsAfterDeletedRow = {}
        let unchangedElements = {}
        for (const rowName in dummyErrorState) {
            const rowAccessor = rowName.split('[')
            if (rowAccessor.length > 1) { // NW-356 check that rowAccessor actually belongs to a grid error
                const position = rowAccessor[1][0] //get the index part of the accessor (which is formatted like `accessor[x]`) as a string this will return just the `x]` part, then get the item in the middle of that string, to get the position 
                const rowData = dummyErrorState[rowName]
    
                if (deletedRowPosition <= position && deletedRecordaccessor === rowAccessor[0]) { // this means we need to shift positions
                    delete dummyErrorState[rowName]  
                    const formattedRowName = `${rowAccessor[0]}[${position - 1}]` //insert it again but now shifted up
                    elementsAfterDeletedRow = { ...elementsAfterDeletedRow, [formattedRowName]: rowData }
                }
                else {
                    const formattedRowName = `${rowAccessor[0]}[${position}]` // if we didn't deleted it insert the same thing again   
                    unchangedElements = { ...unchangedElements, [formattedRowName]: rowData }
                }   
            }
        }
        setErrorState(
            {...unchangedElements, ...elementsAfterDeletedRow }
        )
    }, [errorState])
    
    const columns = useMemo(
        () =>
            parseUploadColumns(
                data,
                config,
                submissionState,
                viewOnly,
                printable,
                uploadConfig,
                setDisplayDeleteModal,
                roles
            ),
        [
            config,
            formMethods,
            accessor,
            submissionState,
            viewOnly,
            printable,
            uploadConfig,
            roles
        ]
    )

    useEffect(() => {
        setData(getDataFromSubmissionState(submissionState, accessor))
    }, [config, submissionState])

    useEffect(() => {
        if (data.length) {
            const lastRowIdx = data.length - 1
            const lastRow = data[lastRowIdx]
            if (!Object.keys(lastRow).some(x => lastRow[x] !== null)) {
                // TODO: When we add a row, make sure the form data is cleared
                Object.keys(lastRow).map(key => {
                    const associatedConfig = config.find(
                        x => x.ControlName === key
                    )
                    const fieldName = getInputNameFromConfig(
                        associatedConfig,
                        key
                    )
                    const formName = `${accessor}[${lastRowIdx}].${fieldName}`

                    // hack for the id keys in dependent tables
                    if (
                        formName.includes('ID') &&
                        !formName.includes('Select')
                    ) {
                        return setValue(
                            formName.replace('ID', 'IDSelect'),
                            null
                        )
                    }
                    return setValue(formName, null)
                })
            }
        }
    }, [data, submissionState, accessor, setValue, config])

    useEffect(() => {
        setTableData(prevTableData => {
            const newTableData = deepClone(prevTableData)
            newTableData[accessor] = data
            return newTableData
        })
    }, [data, accessor])

    const tableData = useMemo(() => data, [data])

    return (
        <SectionWrapper
            title={title}
            subtitle={subtitle}
            displayRequiredText={displayRequiredText}
            requiredText={requiredText}
        >
            {!printable && <PageErrorText string={pageErrorText[accessor]} />}
            {!printable && <GeneralErrors accessor={accessor}/>}
            <div className="formTableWrapper">
                <ConfirmDeleteRecord accessor={accessor}
                    deleteFunction={
                        deleteFunction.bind(
                            this,
                            setData,
                            formMethods,
                            accessor,
                            setFormDirty,
                            config,
                            removeAndShiftErrorState
                        )
                    }
                />
                <EditableTable
                    columns={columns}
                    printable={printable}
                    data={tableData}
                    setData={!viewOnly ? setData : null}
                    enableRowCreation={enableRowCreation}
                    setControlledPageSize={updateControlledPageSize.bind(
                        this,
                        accessor
                    )}
                    beforePageChange={beforePageChange}
                />
            </div>
        </SectionWrapper>
    )
}