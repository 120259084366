import React from 'react'
import {
    FaSlidersH,
    FaThList,
    FaArrowAltCircleUp,
    FaRegArrowAltCircleUp,
    FaChevronRight,
    FaChevronLeft,
} from 'react-icons/fa'

import PanelHeader from '../panel/PanelHeader'
import { IconTooltip as Tooltip } from '../Tooltip'

export default ({
    filterExpanded,
    toggleFilter,
    togglePromotedRecords,
    promotedRecords,
    explorerPage,
    name,
    formName,
    panelTitle,
    extraClass,
}) => {
    return (
        <PanelHeader extraClass={extraClass}>
            <div className="panelHeaderText">{`${panelTitle} Explorer`}</div>
            <div className="panelHeaderControls">
                <div className="panelHeaderIconWrapper">
                    {(!explorerPage && !filterExpanded) && (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                promotedRecords
                                    ? `Do not promote Selected Records`
                                    : `Promote  Selected Records`
                            }`}
                            data-for={`well-promoted-records-toggle`}
                            onClick={() =>
                                togglePromotedRecords(!promotedRecords, name)
                            }
                        >
                            {promotedRecords ? (
                                <FaArrowAltCircleUp />
                            ) : (
                                <FaRegArrowAltCircleUp />
                            )}
                            <Tooltip
                                id={`${formName}-promoted-records-toggle`}
                            />
                        </div>
                    )}
                    {/* display separate toggle styles for solo page vs explorer page */}
                    {explorerPage && (
                        <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? `Hide ${panelTitle} Filter`
                                : `View ${panelTitle} Filter`
                        }`}
                        data-for={`${formName}-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        <span className='headerFilterText'>
                            {filterExpanded ? 'Hide Filter' : 'Show Filter'}
                        </span>
                        {filterExpanded ? <FaChevronRight /> : <FaChevronLeft /> }
                    </div>
                    )}
                    {!explorerPage && (
                        <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? `View ${panelTitle} List`
                                : `View ${panelTitle} Filter`
                        }`}
                        data-for={`${formName}-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        {filterExpanded ? <FaThList /> : <FaSlidersH />}
                        <Tooltip id={`${formName}-view-toggle`} />
                    </div>
                    )}
                    
                </div>
            </div>
        </PanelHeader>
    )
}
