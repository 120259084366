import React, { useEffect, useMemo, useRef } from 'react'
import { useTable, useSortBy, useFilters, useRowSelect } from 'react-table'
import { IconContext } from 'react-icons'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa'
import { matchSorter } from 'match-sorter'

const getHeaderProps = (column, sortable) => {
    if (sortable) {
        return column.getSortByToggleProps()
    } else {
        return {}
    }
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <input
            className="input is-small is-size-7"
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Filter...`}
        />
    )
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <span className="icon">
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </span>
        )
    }
)

const SimpleTable = ({
    columns,
    data,
    sortable = false,
    filterable = false,
    selectable = false,
    onSelect = null
}) => {
    const dispatch = useRef()
    
    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
        }), []
    )
    
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }), []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { selectedRowIds, filters },
    } = useTable(
        {
            columns,
            defaultColumn,
            data,
            filterTypes,
            autoResetSelectedRows: false,
        },
        useFilters,
        useSortBy,
        useRowSelect,
        hooks => {
            if (selectable) {
                hooks.visibleColumns.push(columns => {
                    return [
                        {
                            id: 'selection',
                            // Make this column a groupByBoundary. This ensures that groupBy columns
                            // are placed after it
                            groupByBoundary: true,
                            // The header can use the table's getToggleAllRowsSelectedProps method
                            // to render a checkbox
                            Header: (props) => {
                                const headerProps = props.getToggleAllRowsSelectedProps()
                                dispatch.current = props.dispatch
                                return (
                                    <div>
                                        <IndeterminateCheckbox
                                            {...headerProps}
                                        />
                                    </div>
                                )
                            },
                            // The cell can use the individual row's getToggleRowSelectedProps method
                            // to the render a checkbox
                            Cell: ({ row }) => (
                                <div>
                                    <IndeterminateCheckbox
                                        {...row.getToggleRowSelectedProps()}
                                    />
                                </div>
                            ),
                        },
                        ...columns,
                    ]
                })
            }
        }
    )

    useEffect(() => {
        if (selectable) {
            const selectedIdx = Object.keys(selectedRowIds)
            const associatedRows = data.filter((x, idx) => selectedIdx.includes(idx.toString()))
            onSelect(associatedRows)
        }
    }, [selectedRowIds])

    // reset selected rows when filters change
    useEffect(() => {
        if (selectable) {
            dispatch.current({type: 'resetSelectedRows'})
        }
    }, [filters])

    return (
        <div className="gridWrapper">
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
            >
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} className={`is-size-7 vertical-align ${column.id === "selection" ? "tableSelector" : null}`}>
                                    <div
                                        className={`explorerTableHeader simpleTableHeader`}
                                    >
                                        <div
                                            className="nameAndSort"
                                            {...getHeaderProps(
                                                column,
                                                sortable
                                            )}
                                        >
                                            {column.render('Header')}
                                            <span className="explorerTableArrow simpleTableArrow">
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <IconContext.Provider
                                                            value={{
                                                                className:
                                                                    'has-text-link',
                                                                size: '1.1rem',
                                                            }}
                                                        >
                                                            <FaAngleDown />
                                                        </IconContext.Provider>
                                                    ) : (
                                                        <IconContext.Provider
                                                            value={{
                                                                className:
                                                                    'has-text-link',
                                                                size: '1.1rem',
                                                            }}
                                                        >
                                                            <FaAngleUp />
                                                        </IconContext.Provider>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        </div>
                                        {filterable ? (
                                            <div className="filter">
                                                {column.canFilter
                                                    ? column.render('Filter')
                                                    : null}
                                            </div>
                                        ) : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} >
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            className="is-size-7 vertical-align"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default SimpleTable