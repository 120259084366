import React, {
    createContext,
    useCallback,
    useState,
    useEffect,
    useMemo,
    useContext,
    useRef,
} from 'react'
import dayjs from 'dayjs'
import localForage from 'localforage'
import { debounce } from 'debounce'

import withConfig from '../../../wrappers/withConfig'
import { ParameterContext } from '../../../wrappers/ParameterContext'
import { AppStateContext } from '../AppStateContext'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import { UserContext } from '../../../wrappers/UserContext'

import { getQueryStringFromParams } from '../../../../utils/params'
import toast from '../../../elem/Toast'
import { getRoles, hasAccessToPWS } from '../../../../utils/user/permissions'
import { getCircleAboutLocation } from '../../../../utils/map/helpers'

const DataContext = createContext(null)

const DataContextProvider = ({ config, children }) => {
    const [allData, setAllData] = useState({})
    const [filteredData, setFilteredData] = useState([])
    const [layerData, setLayerData] = useState([])
    const filterRef = useRef([])
    const [loading, setLoading] = useState(true)
    const [layerLoading, setLayerLoading] = useState(false)
    const [tooltipLoading, setTooltipLoading] = useState(true)
    const [addressBufferPolygon, setAddressBufferPolygon] = useState(null)
    const { API_URL } = config

    const { params, setParams } = useContext(ParameterContext)
    const {
        setMapState,
        mapState: { filterFeatures, allFeatures, mapSelectionIds },
    } = useContext(AppStateContext)
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { client, user } = useContext(UserContext)

    const [tooltipData, setTooltipData] = useState({})
    const [tooltipFilterData, setTooltipFilterData] = useState({})
    const [hasFilterApplied, setFilterApplied] = useState(true)

    // load all map data from cache first, network request second
    useMemo(() => {
        setLoading(true)
        client
            .getUser()
            .then(user => {
                const roles = getRoles(user)
                const isAgency = hasAccessToPWS(roles)
                const storageItemName = `mapData:${
                    isAgency ? 'agency' : 'public'
                }`
                localForage.getItem(storageItemName).then(cachedData => {
                    if (cachedData) {
                        const json = JSON.parse(cachedData)
                        if (json && json.data && json.expires) {
                            if (dayjs() < dayjs(json.expires)) {
                                setLoading(false)
                                return setAllData(json.data)
                            }
                        }
                    }
                    authenticatedFetch(`${API_URL}/well/map`, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers':
                                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                        },
                        body: JSON.stringify({selectedIds: "", mapSelectionIds: "" })
                    })
                        .then(async response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                const error = await response.text()
                                throw new Error(error)
                            }
                        })
                        .then(response => {
                            const sessionObject = {
                                expires: dayjs().add(1, 'day'),
                                data: response.data,
                            }
                            localForage
                                .setItem(
                                    storageItemName,
                                    JSON.stringify(sessionObject)
                                )
                                .then(() => {
                                    setAllData(response.data)
                                })
                                .catch(e => {
                                    toast({
                                        level: 'info',
                                        message:
                                            'Well Map: Unable to store data locally. This will effect map loading times.',
                                    })
                                })
                        })
                        .catch(e => {
                            toast({
                                level: 'error',
                                message:
                                    'Well Map: ' +
                                    (e.message
                                        ? e.message
                                        : 'Unable to connect to the server. Please try again later.'),
                            })
                        })
                        .finally(() => setLoading(false))
                })
            })
            .catch(() => {})
    }, [user])

    // load map layer data
    useEffect(() => {
        setLayerLoading(true)
        authenticatedFetch(`${API_URL}/map/layers`)
            .then(async response => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(response => {
                setLayerData(response)
            })
            .catch(e => {
                toast({
                    level: 'error',
                    message:
                        'Map Layers:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            })
            .finally(() => setLayerLoading(false))
    }, [API_URL])

    const filterData = useCallback(params => {
        if (allData.features) {
            const parameterString = getQueryStringFromParams(params, true)
            if (parameterString || (mapSelectionIds !== "") ) {
                const selectedIds = (filterFeatures && filterFeatures.length) ? encodeURI(filterFeatures).toString() : ''
                setLayerLoading(true)
                authenticatedFetch(`${API_URL}/well/map?${parameterString}`, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Headers':
                                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
                        },
                        body: JSON.stringify({ selectedIds, mapSelectionIds })
                    })
                    .then(async response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            const error = await response.text()
                            throw new Error(error)
                        }
                    })
                    .then(response => {
                        const d = response.data.features.map(
                            x => x.properties.FacilityID
                        )
                        setFilteredData(d)
                        filterRef.current = d
                        setFilterApplied(true)
                    })
                    .catch(e => {
                        toast({
                            level: 'error',
                            message:
                                'Map: ' +
                                (e.message
                                    ? e.message
                                    : 'Unable to connect to the server. Please try again later.'),
                        })
                    })
                    .finally(() => setLayerLoading(false))
            } else {
                const d = allData.features.map(
                    x => x.properties.FacilityID
                )
                setFilterApplied(false)
                setFilteredData(d)
            }
        }
    }, [filterFeatures, allData, mapSelectionIds])

    const fetchTooltipDataDebounce = debounce(
        facilityID => {
            authenticatedFetch(`${API_URL}/well/tooltip/${facilityID}`)
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .then(response => {
                    setTooltipData(response.data)
                    setTooltipFilterData(response.filterData)
                    setTooltipLoading(false)
                })
                .catch(e => {
                    toast({
                        level: 'error',
                        message:
                            'Well Map: ' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server. Please try again later.'),
                    })
                })
        },
        300,
        false
    )

    const fetchTooltipData = facilityID => {
        setTooltipLoading(true)
        fetchTooltipDataDebounce.clear()
        fetchTooltipDataDebounce(facilityID)
    }

    const updateFilteredData = newData => {
        const f = filterRef.current
        const updatedData = [...f, ...newData]
        filterRef.current = updatedData
        setFilteredData(updatedData)
        setMapState(prevMapState => ({
            ...prevMapState,
            filteredData: updatedData,
        }))
    }

    const clearSelectionLayer = () => {
        setMapState(mapState => ({ ...mapState, selectedFeatures: [] }))
        setAddressBufferPolygon(null)
    }

    // fetch data on parameter changes or filterByMapSelection change
    useEffect(() => {
        filterData(params)
    }, [params['well'], allData, mapSelectionIds])

    const selectWellsWithinRadius = useCallback((location, searchRadius, units) => {
        return new Promise((resolve, reject) => {
            const circleFeature = getCircleAboutLocation(location, searchRadius, units)
            if (!circleFeature) {
                return reject('Unable to construct a buffer around this address.')
            }
            const circleGeometry = circleFeature.getGeometry()
            setAddressBufferPolygon(circleFeature)
            const featuresWithinCircle = allFeatures
                .filter(feature =>{
                    const displayed = feature.get('displayed') 
                    const intersects = circleGeometry.intersectsCoordinate(
                        feature.getGeometry().getCoordinates()
                    )
                    return displayed && intersects
                })
            setMapState(prevState => ({
                ...prevState,
                selectedFeatures: featuresWithinCircle
            }))
            return resolve()
        })
    }, [filteredData, allFeatures])

    return (
        <DataContext.Provider
            value={{
                data: allData,
                filteredData,
                layerData,
                tooltipData,
                tooltipFilterData,
                layerLoading,
                tooltipLoading,
                fetchTooltipData,
                setFilteredData,
                updateFilteredData,
                clearSelectionLayer,
                selectWellsWithinRadius,
                addressBufferPolygon,
                setAddressBufferPolygon,
                params,
                setParams,
                loading,
                hasFilterApplied
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export { DataContext }
export default withConfig(DataContextProvider)
