import React from 'react'
import ResetAnalytesButton from './ResetAnalytesButton'
import ResetZoomButton from './ResetZoomButton'

const ResetSelector = ({
    resetExpanded,
    resetZoomFunction,
    resetAnalytesFunction,
}) => {
    if (!resetExpanded) {
        return null
    }
    return (
        <div className="timeWindowDropdownWrapper">
            <ResetZoomButton resetFunction={resetZoomFunction} />
            <ResetAnalytesButton resetFunction={resetAnalytesFunction} />
        </div>
    )
}

export default ResetSelector
