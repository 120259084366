import React from 'react'

import { PanelStyleContext } from '../../panel/PanelStyleContext'

const TableFooter = ({
    filterExpanded,
    explorerPage,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageCount,
    resultsCount,
    pageOptions,
    pageIndex,
    pageSize,
    setPageSize,
}) => (
    <PanelStyleContext.Consumer>
        {panelContext => {
            const backgroundColorClass = panelContext.backgroundColorClass
            return (
                <div
                    className={`tableFooterWrapper ${
                        (filterExpanded  && !explorerPage) ? 'is-hidden' : 'is-visible'
                    }`}
                >
                    <div className="tableFooter">
                        <div className="buttons are-small">
                            <button
                                className={`button is-primary ${backgroundColorClass}`}
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {'<<'}
                            </button>{' '}
                            <button
                                className={`button is-primary ${backgroundColorClass}`}
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {'<'}
                            </button>{' '}
                            <button
                                className={`button is-primary ${backgroundColorClass}`}
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {'>'}
                            </button>{' '}
                            <button
                                className={`button is-primary ${backgroundColorClass}`}
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {'>>'}
                            </button>{' '}
                        </div>
                        <div className="resultsCount margin-left-sm">
                            <span className="margin-right-sm">
                                <strong>{resultsCount}</strong> Total Results
                            </span>
                            <span className="margin-right-sm">
                                | Page <strong>{pageIndex + 1}</strong> of{' '}
                                <strong>{pageOptions.length}</strong>
                            </span>
                        </div>
                        <div className="pageNavigator">
                            <span>| Go to page:</span>
                            <div className="field is-horizontal">
                                <div className="control">
                                    <input
                                        type="number"
                                        className="input is-small margin-left-sm"
                                        value={pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0
                                            gotoPage(page)
                                        }}
                                        style={{ width: '100px' }}
                                    />
                                </div>
                            </div>
                            <div className="field is-horizontal">
                                <div className="control">
                                    <div className={`select is-small margin-left-sm ${panelContext.selectDropDownCaratClass}`}>
                                        <select
                                            // className="select is-small margin-left-sm"
                                            value={pageSize}
                                            onChange={e => {
                                                setPageSize(
                                                    Number(e.target.value)
                                                )
                                            }}
                                        >
                                            {[10, 20, 30, 40, 50].map(
                                                pageSize => (
                                                    <option
                                                        key={pageSize}
                                                        value={pageSize}
                                                    >
                                                        Show {pageSize}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}
    </PanelStyleContext.Consumer>
)

export default TableFooter
