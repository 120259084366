import React, {
    useState,
    useContext,
    useMemo,
    useEffect,
    useCallback,
} from 'react'
import { useForm } from 'react-hook-form'
import withConfig from '../../../../wrappers/withConfig'

import { FacilityAssignmentContext } from './DataContext'
import TableContent from '../../../../elem/table/ssr/TableContent'
import { DataContext } from '../../DataContext'
import NoDataComponent from '../../../../elem/table/ssr/NoDataComponent'
import parseColumns from '../../../../../utils/table/parseColumns'
import deepEqual from 'deep-equal'
import clickSelection from '../../../../../utils/table/clickSelection'

export default withConfig(({ config }) => {
    const {
        setFormMethods,
        setFormDirty,
        setSubmissionState,
        submissionState,
        viewOnly,
        activePanel
    } = useContext(DataContext)
    const { FACILITY_TITLE, LINK_COLUMN, ID_COLUMN } = config
    const [data, setData] = useState([])
    const {
        assignedFacilityData,
        params,
        setParams,
        loadingAssignedFacilities,
        projectFacilityAccessor,
        projectFacilityListConfig,
        highlightedAssignedFacilities,
        setHighlightedAssignedFacilities,
    } = useContext(FacilityAssignmentContext)
    // const { pageSize } = assignedFacilityMetadata
    // const adjustedPageCount = (count / pageSize) % 1 === 0 ? pageCount - 1 : pageCount
    const defaultValues = useMemo(
        () => (assignedFacilityData ? assignedFacilityData : null),
        [assignedFacilityData]
    )

    const formConfig = defaultValues
        ? { mode: 'onChange', defaultValues }
        : { mode: 'onChange' }
    const formMethods = useForm(formConfig)
    const { handleSubmit } = formMethods

    const dirty = useMemo(() => {
        const existingData =
            submissionState && submissionState[projectFacilityAccessor]
                ? submissionState[projectFacilityAccessor]
                : []
        const isDirty = !deepEqual(
            [...new Set(existingData.map(x => x['facilityId']).sort())],
            assignedFacilityData.map(x => x[ID_COLUMN]).sort()
        )
        return isDirty
    }, [submissionState, assignedFacilityData])

    useEffect(() => {
        if (activePanel === 'Sample Point Assignments') {
            setFormMethods(formMethods)
        }
    }, [defaultValues])

    useEffect(() => {
        formMethods.reset(defaultValues)
    }, [defaultValues])

    useEffect(() => {
        setData(assignedFacilityData)
    }, [assignedFacilityData])

    useEffect(() => {
        if (!loadingAssignedFacilities) 
            setFormDirty(dirty)
    }, [dirty, loadingAssignedFacilities])

    const count = useMemo(() => assignedFacilityData.length, [
        assignedFacilityData,
    ])

    const columns = useMemo(() =>
        parseColumns(
            assignedFacilityData,
            projectFacilityListConfig,
            LINK_COLUMN,
            ID_COLUMN
        )
    )

    const selectedItems = useMemo(
        () => highlightedAssignedFacilities.map(x => x[ID_COLUMN]),
        [highlightedAssignedFacilities, ID_COLUMN]
    )

    const [lastSelection, setLastSelection] = useState(null)

    const onSubmit = useCallback(
        d => {
            setSubmissionState(prevSubmissionState => ({
                ...prevSubmissionState,
                [projectFacilityAccessor]: data,
            }))
        },
        [data]
    )

    const onClick = useCallback(
        (row, isHighlighted, e, page) => {
            clickSelection(
                e,
                row,
                page,
                highlightedAssignedFacilities,
                assignedFacilityData,
                lastSelection,
                ID_COLUMN,
                setHighlightedAssignedFacilities,
                setLastSelection
            )
        },
        [highlightedAssignedFacilities, assignedFacilityData, lastSelection]
    )

    const updateParams = newParams => {
        setParams(newParams, 'assignedFacilities')
        setHighlightedAssignedFacilities([])
    }

    return (
        <div
            className={`assignedFacilitiesPositioning ${
                viewOnly ? 'fullWidthPositioning' : ''
            }`}
        >
            <div className="assignedFacilitiesWrapper">
                <div className="has-text-centered is-size-5 has-text-weight-semibold">
                    {`Assigned ${FACILITY_TITLE}s`}
                </div>
                <form
                    id="assigned-facilities"
                    className="form is-multiline"
                    onSubmit={handleSubmit(() => onSubmit())}
                >
                    {count === 0 && !loadingAssignedFacilities ? (
                        <div className="column is-12 has-text-centered">
                            <NoDataComponent
                                filterExpanded={false}
                                toggleFilter={() => {}}
                                subject={
                                    'No Sample Points are associated with this project yet.'
                                }
                                message={''}
                            />
                        </div>
                    ) : (
                        <TableContent
                            name="assignedFacilities"
                            {...{
                                columns,
                                loading: loadingAssignedFacilities,
                                params,
                                onClick,
                                setParams: updateParams,
                                resultsCount: count,
                                // pageCount: adjustedPageCount,
                                data: assignedFacilityData,
                                filterExpanded: false,
                                filterFields: projectFacilityListConfig,
                                showAppliedFilterString: false,
                                extraClass: `facilityAssignmentTable`,
                                selectedItems: selectedItems,
                                selectedItemColumn: ID_COLUMN,
                                manualPagination: false,
                            }}
                        />
                    )}
                </form>
            </div>
        </div>
    )
})