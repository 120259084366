import React, { useCallback, useContext, useMemo } from 'react'
import Modal from '../../../elem/Modal'
import toast from '../../../elem/Toast'

import { TableContext } from './TableContext'

const CommentsField = ({ setBulkComments, bulkComments }) => (
    <div className="field is-horizontal ">
        <div className="field-label is-align-items-center">
            <label className="label">Comments:</label>
        </div>
        <div className="field-body field control">       
            <textarea rows="10"
                className="input is-fullwidth"
                value={bulkComments}
                onChange={e => {
                    setBulkComments(e.target.value)
                }}
            ></textarea>
        </div>
    </div>
)

const BulkSubmitButton = ({ action }) => {
    const { 
        selectedUploads,
        bulkSubmit,
        setBulkComments,
        bulkComments,
        resolveBulkModalState,
        displayBulkApproveModal,
        displayBulkDenyModal
    } = useContext(TableContext)
    const allSelectedUploadsSubmitted = useMemo(() => selectedUploads.every(x => x.Status === 'Submitted'), [selectedUploads])
    const allSelectedUploadsValid = useMemo(() => selectedUploads.every(x => x.IsValid === 'Pass'), [selectedUploads])
    const isApprove = action === 'Approve'
    const isDeny =  action === 'Deny'
    const isSubmit = action === 'Submit'

    const onApproveDenyClick = useCallback(() => {
        if (isApprove) {
            if (!allSelectedUploadsSubmitted || !allSelectedUploadsValid) {
                const approveActionMessage = `All selected forms must have Status of Submitted and Validation Status of Pass in order to approve`
                toast({
                    level: 'error',
                    message: approveActionMessage,
                    alert: true,
                })
                return // finish early with error message
            }
        } else if(isDeny) {
            if (!allSelectedUploadsSubmitted) {
                const denyActionMessage = `All selected forms must have Status of Submitted in order to deny`
                toast({
                    level: 'error',
                    message: denyActionMessage,
                    alert: true,
                })
                return // finish early with error message
            }
        }
        resolveBulkModalState(action, true)
    }, [action, selectedUploads, isDeny, isApprove, isSubmit, allSelectedUploadsValid, allSelectedUploadsSubmitted])

    const onSubmitClick = useCallback(() => {  
        if (!allSelectedUploadsValid) {
            toast({
                level: 'error',
                message:
                    `All selected forms must have Validation Status of Pass in order to submit`,
                alert: true,
            })
            return
        }
        bulkSubmit(action)
    }, [action, selectedUploads, allSelectedUploadsValid, allSelectedUploadsSubmitted])

    return (
        <div className="bulkSubmitWrapper">
                <Modal
                    display={isApprove ? displayBulkApproveModal : displayBulkDenyModal}
                    confirmAction={() => bulkSubmit(action)}
                    confirmText={`${action} Forms`}
                    denyAction={() => resolveBulkModalState(action, false)}
                    denyText={`Cancel`}
                    title={`${action} Forms`}
                >
                    <CommentsField setBulkComments={setBulkComments} bulkComments={bulkComments}/>
                </Modal>
            <button 
                className="button is-normal is-link bulkSubmitButton" 
                disabled={selectedUploads.length === 0} 
                onClick={() =>  isSubmit ? onSubmitClick() : onApproveDenyClick(action)}
                >
                {action}
            </button>
        </div>
    )
}

export default BulkSubmitButton