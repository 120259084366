import React, { useContext, useMemo } from 'react'

import { DataContext } from '../DataContext'
import Nav from './Nav'
import SubmitButton from './SubmitButton'
import LoadingWrapper from '../../../wrappers/LoadingWrapper'
import Content from './Content'
import Breadcrumbs from './Breadcrumbs'
import StatusBanner from '../StatusBanner'
import UnsavedChanges from '../UnsavedChanges'
import ContinueAnyway from '../ContinueAnyway'
import BeforePageChange from '../BeforePageChange'
import { useLocation } from 'react-router-dom'

export default () => {
    const { uploadConfig, isReview, viewOnly, submissionState, formType } = useContext(DataContext)
    const location = useLocation()

    const samplingPointIdentifiers = useMemo(() => {
        if (formType.FormType === 'Sample Point') {
            if (submissionState && submissionState.facility) {
                const facilityId = submissionState.facility.facilityId
                const associatedFacilityId = submissionState.facility.associatedFacilityId
                return {
                    facilityId,
                    associatedFacilityId,
                }
            }
            // if (location.state && location.state.clearinghouse) {
            //     return location.state.clearinghouse
            // }
            return {}
        }
    }, [submissionState, location, formType])

    const projectIdentifiers = useMemo(() => {
        if (formType.APILink === 'project') {
            if (submissionState && submissionState.project) {
                const apiNumber = submissionState.project.apinumber
                return {
                    apiNumber,
                }
            }
            return {}
        }
    }, [submissionState, location, formType])
    return (
        <div className="hero is-fullheight-with-navbar unset-justify-content">
            <Breadcrumbs />
            <div className="columns no-margin is-multiline">
                <StatusBanner uploadData={submissionState && submissionState.upload ? submissionState.upload : null} />
                <div className='identifier-ribbon'><p>
                    {submissionState && submissionState.facility && (submissionState.facility.facilityId || submissionState.facility.uploadFacilityId) ? (<>
                        <b>Associated Facility ID:</b> { samplingPointIdentifiers.associatedFacilityId } &nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Sample Point ID:</b> { samplingPointIdentifiers.facilityId } 
                    </>) : null}
                    {submissionState && submissionState.project && (submissionState.project.projectId || submissionState.project.uploadProjectId) ? (<>
                        <b>Project Number:</b> { projectIdentifiers.apiNumber } 
                    </>) : null}
                </p></div>
                <UnsavedChanges />
                <ContinueAnyway />
                <BeforePageChange />
                <div className="column is-12">
                    <div>
                        <div>
                            <div className="columns is-multiline">
                                <LoadingWrapper data={uploadConfig}>
                                    <div className="column is-10">
                                        <StatusBanner />
                                        <Content />
                                    </div>
                                    <div className="column is-2">
                                        <Nav />
                                        { !(isReview || viewOnly)  && <SubmitButton />}
                                    </div>
                                </LoadingWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
